define("dragon-dice/pods/army/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FgwvFgMz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"one-column element--\",[24,[\"model\",\"color\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"units\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\",true],[8],[0,\"\\n      \"],[1,[28,\"unit-details\",null,[[\"unit\",\"slide\"],[[24,[\"model\"]],false]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"article\",true],[8],[0,\"\\n      \"],[7,\"q\",true],[8],[0,\"This army contains no units.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/army/details/template.hbs"
    }
  });

  _exports.default = _default;
});