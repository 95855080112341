define("dragon-dice/helpers/i", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.icon = icon;
  _exports.default = void 0;

  function icon([icon]) {
    return Ember.String.htmlSafe(`
    <svg class="icon">
      <use xlink:href="/assets/icons.svg#i-${icon}"></use>
    </svg>
  `);
  }

  var _default = Ember.Helper.helper(icon);

  _exports.default = _default;
});