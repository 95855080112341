define("dragon-dice/utils/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(enumerable, property) {
    let result = Ember.A();
    enumerable.forEach(item => {
      let value = Ember.get(item, property),
          group = result.findBy('value', value);

      if (!group) {
        group = Ember.Object.create({
          value,
          items: Ember.A()
        });
        result.pushObject(group);
      }

      Ember.get(group, 'items').pushObject(item);
    });
    return result;
  }
});