define("dragon-dice/pods/components/x-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IYcp5KFc",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select\",null,[[\"selected\",\"options\",\"onchange\",\"renderInPlace\",\"searchEnabled\"],[[24,[\"value\"]],[24,[\"options\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null],true,false]],{\"statements\":[[0,\"  \"],[1,[23,1,[]],false],[0,\" \"],[1,[28,\"i\",[\"norgie\"],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/x-select/template.hbs"
    }
  });

  _exports.default = _default;
});