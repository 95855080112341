define("dragon-dice/models/unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const faceValueMapThingy = {
    // melee, missile, magic, maneuver, save
    id: [1, 1, 1, 1, 1],
    melee: [1, 0, 0, 0, 0],
    missile: [0, 1, 0, 0, 0],
    magic: [0, 0, 1, 0, 0],
    maneuver: [0, 0, 0, 1, 0],
    save: [0, 0, 0, 0, 1],
    sai: [0, 0, 0, 0, 0],
    // TODO
    // SAIs
    bullseye: [0, 1, 0, 0, 0],
    cantrip: [0, 0, 1, 0, 0],
    counter: [1, 0, 0, 0, 1],
    fly: [0, 0, 0, 1, 1],
    rend: [1, 0, 0, 1, 0],
    smite: [1, 0, 0, 0, 0],
    tail: [1, 0, 0, 0, 0],
    trample: [1, 0, 0, 1, 0]
  };
  const rerollSais = {
    melee: ['rend', 'tail'],
    missile: ['bullseye']
  };

  var _default = Ember.Object.extend({
    faceBreakdown: Ember.computed('faces', 'qty', function () {
      let rounding = 1e3;
      let faces = this.get('faces'),
          breakdown = {
        melee: [],
        missile: [],
        magic: [],
        maneuver: [],
        save: []
      };
      faces.map(this._mapFace.bind(this)).forEach(type => {
        let [melee, missile, magic, maneuver, save] = type;
        breakdown.melee.push(melee);
        breakdown.missile.push(missile);
        breakdown.magic.push(magic);
        breakdown.maneuver.push(maneuver);
        breakdown.save.push(save);
      });
      return window.Object.keys(breakdown).map(type => ({
        type,
        // average:   Math.round(this._average(breakdown[type]) * rounding) / rounding,
        average: Math.round(this._averageNew(faces, breakdown[type], type) * rounding) / rounding,
        favResult: Math.round(this._favResult(breakdown[type]) * rounding) / rounding,
        stDev: Math.round(this._stDev(breakdown[type]) * rounding) / rounding,
        stDevNew: this._stDevNew(faces, type),
        qty: this.getWithDefault('qty', 1)
      }));
    }),
    subtitle: Ember.computed('rarity', 'type', function () {
      if (this.rarity === this.type) {
        return `${this.type} unit`;
      }

      return `${this.rarity} ${this.type} unit`;
    }),

    _mapFace(face) {
      let mapper = faceValueMapThingy[face.action || face.type];

      if (mapper) {
        return mapper.map(x => x * face.value);
      } else {
        console.warn('unknown face map:', face.action);
        return [0, 0, 0, 0, 0];
      }
    },

    _favResult(values) {
      return values.filter(x => x).length / values.length;
    },

    _average(values) {
      return values.reduce((a, b) => a + b, 0) / values.length;
    },

    _averageNew(faces, breakdown, type) {
      let ignoredFaces = faces.filter(x => (rerollSais[type] || []).indexOf(x.action) !== -1).length;
      let total = breakdown.reduce((a, b) => a + b, 0);
      let participatingFaces = breakdown.length - ignoredFaces;
      return total / participatingFaces;
    },

    _stDev(values) {
      let avg = this._average(values);

      let squareDiffs = values.map(value => Math.pow(value - avg, 2));
      return Math.sqrt(this._average(squareDiffs));
    },

    _stDevNew()
    /* faces, type */
    {
      // console.log(arguments);
      return -1;
    }

  });
  /*
  For these, the mean and variance are calculated by dividing by the number of sides MINUS the number of sides giving the re-roll for that action. Instead of adding together the number of missile results and dividing by the number of sides, in the case of a bullseye, you add up the missile results and divide by number of sides minus the number of bullseye SAIs. Ditto with the variance, as this is just the average of the distances from the mean squared and then added together.
  E.g. Devastator- Sum of missile results is 14. Divide by 5 and not 6, to give 2.8 as your mean instead of 2.33
  */


  _exports.default = _default;
});