define("dragon-dice/pods/components/unit-details/unit-faces/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lrHta0OW",
    "block": "{\"symbols\":[\"face\",\"@unit\"],\"statements\":[[7,\"h5\",true],[8],[0,\"Faces\"],[9],[0,\"\\n\"],[7,\"ol\",true],[11,\"class\",[29,[\"list-faces \",[23,2,[\"type\"]]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[\"faces\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[7,\"figure\",true],[8],[0,\"\\n        \"],[1,[28,\"unit-face\",null,[[\"face\",\"unit\",\"small\"],[[23,1,[]],[23,2,[]],true]]],false],[0,\"\\n        \"],[7,\"figcaption\",true],[8],[1,[28,\"label-face\",[[23,1,[]]],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/unit-details/unit-faces/template.hbs"
    }
  });

  _exports.default = _default;
});