define("dragon-dice/pods/species-dragons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n0LQGp5q",
    "block": "{\"symbols\":[],\"statements\":[[5,\"banner\",[[12,\"class\",\"species-dragons\"]],[[\"@title\"],[\"Dragons\"]],{\"statements\":[[0,\"\\n  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"link-left\",\"index.species\"]],{\"statements\":[[1,[28,\"i\",[\"back\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\"],[\"species-dragons.units\"]],{\"statements\":[[0,\"Units\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\"],[\"species-dragons.sais\"]],{\"statements\":[[0,\"Faces\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\"],[\"species-dragons.breath\"]],{\"statements\":[[0,\"Breath\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[1,[28,\"liquid-outlet\",null,[[\"enableGrowth\"],[false]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species-dragons/template.hbs"
    }
  });

  _exports.default = _default;
});