define("dragon-dice/helpers/format-number", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.default = void 0;

  function formatNumber([value], {
    format
  }) {
    return (0, _numeral.default)(value).format(format);
  }

  var _default = Ember.Helper.helper(formatNumber);

  _exports.default = _default;
});