define("dragon-dice/services/spells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getElements() {
      return [{
        id: 'air',
        title: 'Air'
      }, {
        id: 'water',
        title: 'Water'
      }, {
        id: 'earth',
        title: 'Earth'
      }, {
        id: 'fire',
        title: 'Fire'
      }, {
        id: 'death',
        title: 'Death'
      }, {
        id: 'elemental',
        title: 'Elemental',
        description: 'All elemental spells are available to be cast in any of the 5 elemental colors. All points for the spell must come from the same color of units and/or items. In the case of species spells, all the points for the spell must come from the same color species’s units and/or items with matching colors.'
      }];
    },

    getElement(id) {
      let element = this.getElements().findBy('id', id),
          spells = allSpells.filterBy('element', id).map(s => {
        if (s.spellList) {
          Ember.set(s, 'spellList', s.spellList.split('-').map(x => x.capitalize()).join(' '));
        }

        return s;
      });
      return Object.assign({}, element, {
        spells
      });
    },

    getForSpecies(species) {
      return allSpells.filter(spell => {
        return spell.spellList === species.id || species.id === 'amazons' && !spell.spellList && spell.element === 'elemental' || !spell.spellList && species.elements.includes(spell.element);
      }).map(s => {
        if (s.spellList) {
          Ember.set(s, 'spellList', s.spellList.split('-').map(x => x.capitalize()).join(' '));
        }

        return s;
      }).sortBy('cost');
    }

  });

  _exports.default = _default;
  let allSpells = [// Death =====================================================================
  {
    id: 'palsy',
    title: 'Palsy',
    element: 'death',
    cost: 2,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: true,
    description: 'Target any opposing army. Subtract [one] result from the target’s non-maneuver rolls until the beginning of your next turn.'
  }, {
    id: 'decay',
    title: 'Decay',
    element: 'death',
    spellList: 'goblins',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing army. Subtract [two] melee results from the target’s rolls until the beginning of your next turn.'
  }, {
    id: 'evil-eye',
    title: 'Evil Eye',
    element: 'death',
    spellList: 'undead',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing army. Subtract [two] save results from the target’s rolls until the beginning of your next turn.'
  }, {
    id: 'magic-drain',
    title: 'Magic Drain',
    element: 'death',
    spellList: 'frostwings',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Subtract [two] magic results from all army rolls at that terrain until the beginning of your next turn.'
  }, {
    id: 'restless-dead',
    title: 'Restless Dead',
    element: 'death',
    spellList: 'undead',
    cost: 3,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: true,
    description: 'Target any army. Add [three] maneuver results to the target’s rolls until the beginning of your next turn.'
  }, {
    id: 'swamp-fever',
    title: 'Swamp Fever',
    element: 'death',
    spellList: 'swamp-stalkers',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target [three] health-worth of unit(s) in an opposing army. Roll the targets. If they roll an ID result, they are killed. Any units killed by Swamp Fever make a second roll. If they roll an ID result they are buried.'
  }, {
    id: 'finger-of-death',
    title: 'Finger of Death',
    element: 'death',
    cost: 4,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing unit. Inflict [one] point of damage on the target with no save possible.'
  }, {
    id: 'necromantic-wave',
    title: 'Necromantic Wave',
    element: 'death',
    spellList: 'lava-elves',
    cost: 5,
    multiplicative: false,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target any army. All units in the target army may count magic results as if they were melee or missile results until the beginning of your next turn.'
  }, {
    id: 'exhume',
    title: 'Exhume',
    element: 'death',
    spellList: 'undead',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target [three] health-worth of units in an opposing player’s DUA. The targets make a save roll. If the targets do not generate a save result they are buried. You may return units up to the health-worth of units buried in this way, to the casting army from your DUA.'
  }, {
    id: 'open-grave',
    title: 'Open Grave',
    element: 'death',
    spellList: 'undead',
    cost: 5,
    multiplicative: false,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target any army. Until the beginning of your next turn, units in the target army that are killed following a save roll by any army-targeting effects (including melee and missile damage) go to their owner’s Reserve Area instead of the DUA. If no save roll was possible when units are killed, Open Grave does nothing.'
  }, {
    id: 'soiled-ground',
    title: 'Soiled Ground',
    element: 'death',
    cost: 6,
    multiplicative: false,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Until the beginning of your next turn, any unit killed at that terrain that goes into the DUA must make a save roll. Those that do not generate a save result are buried.'
  }, // Air =======================================================================
  {
    id: 'hailstorm',
    title: 'Hailstorm',
    element: 'air',
    cost: 2,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: true,
    description: 'Target any opposing army. Inflict [one] point of damage on the target.'
  }, {
    id: 'blizzard',
    title: 'Blizzard',
    element: 'air',
    spellList: 'coral-elves',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Subtract [three] melee results from all army results at that terrain until the beginning of your next turn.'
  }, {
    id: 'wilding',
    title: 'Wilding',
    element: 'air',
    spellList: 'feral',
    cost: 3,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target any army. The target army may double the melee and save results of any [one] unit until the beginning of your next turn. Select a unit to double their results after the army makes each roll.'
  }, {
    id: 'wind-walk',
    title: 'Wind Walk',
    element: 'air',
    cost: 4,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target any army. Add [four] maneuver results to the target’s rolls until the beginning of your next turn.'
  }, {
    id: 'fields-of-ice',
    title: 'Fields of Ice',
    element: 'air',
    spellList: 'frostwings',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Subtract [four] maneuver results from all army results at that terrain until the beginning of your next turn. Ties in maneuver rolls at that terrain are won by the counter-maneuvering army while the terrain is under the effect of Fields of Ice.'
  }, {
    id: 'mirage',
    title: 'Mirage',
    element: 'air',
    spellList: 'firewalkers',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target [five] health-worth of units at any terrain. The targets make a save roll. Those that do not generate a save result are moved to their Reserve Area.'
  }, {
    id: 'lightning-strike',
    title: 'Lightning Strike',
    element: 'air',
    cost: 6,
    multiplicative: false,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing unit. The target makes a save roll. If it does not generate a save result, it is killed. A unit may not be targeted by more than one Lightning Strike per magic action.'
  }, // Earth =====================================================================
  {
    id: 'stoneskin',
    title: 'Stone Skin',
    element: 'earth',
    cost: 2,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: true,
    description: 'Target any army. Add [one] save result to the target’s rolls until the beginning of your next turn.'
  }, {
    id: 'path',
    title: 'Path',
    element: 'earth',
    cost: 4,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target [one] of your units at a terrain. Move the target to any other terrain where you have an army.'
  }, {
    id: 'berserker-rage',
    title: 'Berserker Rage',
    element: 'earth',
    spellList: 'feral',
    cost: 5,
    multiplicative: false,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target an army containing at least one Feral unit. All Feral units in the target army may count save results as if they were melee results during all counter-attacks, until the beginning of your next turn.'
  }, {
    id: 'higher-ground',
    title: 'Higher Ground',
    element: 'earth',
    spellList: 'dwarves',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing army. The target subtracts [five] melee results from their rolls until the beginning of your next turn.'
  }, {
    id: 'scent-of-fear',
    title: 'Scent of Fear',
    element: 'earth',
    spellList: 'goblins',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target up to [three] health-worth of opposing units at any terrain. The target units are moved to their Reserve Area.'
  }, {
    id: 'wall-of-thorns',
    title: 'Wall of Thorns',
    element: 'water',
    spellList: 'treefolk',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain not at its eighth face. Any army that successfully maneuvers that terrain takes [six] damage. The army makes a melee roll. Reduce the damage taken by the number of melee results generated. This effect lasts until the beginning of your next turn.'
  }, {
    id: 'transmute-rock-to-mud',
    title: 'Transmute Rock to Mud',
    element: 'earth',
    cost: 6,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing army. Subtract [six] maneuver results from the target’s rolls until the beginning of your next turn.'
  }, // Water =====================================================================
  {
    id: 'watery-double',
    title: 'Watery Double',
    element: 'water',
    cost: 2,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: true,
    description: 'Target any army. Add [one] save result to the target’s rolls until the beginning of your next turn.'
  }, {
    id: 'accelerated-growth',
    title: 'Accelerated Growth',
    element: 'water',
    spellList: 'treefolk',
    cost: 3,
    multiplicative: false,
    castFromReserves: true,
    castableWithCantrip: true,
    description: 'Target your DUA. When a two (or greater) health-worth Treefolk unit is killed, you may instead exchange it with a one health-worth Treefolk unit from your DUA. This effect lasts until the beginning of your next turn.'
  }, {
    id: 'flash-flood',
    title: 'Flash Flood',
    element: 'water',
    cost: 4,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Reduce that terrain one step unless an opposing army at that terrain generates at least [six] maneuver results. A terrain may never be reduced by more than one step during a player’s turn from the effects of Flash Flood.'
  }, {
    id: 'deluge',
    title: 'Deluge',
    element: 'water',
    spellList: 'coral-elves',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Subtract [three] maneuver and [three] missile results from all army rolls at that terrain until the beginning of your next turn.'
  }, {
    id: 'mire',
    title: 'Mire',
    element: 'water',
    spellList: 'swamp-stalkers',
    cost: 5,
    multiplicative: false,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Until the beginning of your next turn, any army marching at that terrain must first make a maneuver roll. The marching player then selects health-worth of units equal to the maneuver results generated by this first roll. The army uses only those units, and items they carry, for any rolls in the march for both the maneuver step and the action step.'
  }, {
    id: 'tidal-wave',
    title: 'Tidal Wave',
    element: 'water',
    spellList: 'scalders',
    cost: 5,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Each army at that terrain takes [four] points of damage, and makes a combination save and maneuver roll. The terrain is reduced one step unless an army generates at least [four] maneuver results. A terrain may never be reduced by more than one step during a player’s turn from the effects of Tidal Wave.'
  }, {
    id: 'wall-of-fog',
    title: 'Wall of Fog',
    element: 'water',
    cost: 6,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Subtract [six] missile results from any missile attack targeting an army at that terrain until the beginning of your next turn.'
  }, // Fire ======================================================================
  {
    id: 'ash-storm',
    title: 'Ash Storm',
    element: 'fire',
    cost: 2,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: true,
    description: 'Target any terrain. Subtract [one] result from all army rolls at that terrain until the beginning of your next turn.'
  }, {
    id: 'fearful-flames',
    title: 'Fearful Flames',
    element: 'fire',
    spellList: 'lava-elves',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing unit. Inflict [one] point of damage on the target. If the target is killed by Fearful Flames it makes a save roll. If no save result is generated, that unit is buried.'
  }, {
    id: 'firebolt',
    title: 'Firebolt',
    element: 'fire',
    spellList: 'dwarves',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing unit. Inflict [one] point of damage on the target.'
  }, {
    id: 'firestorm',
    title: 'Firestorm',
    element: 'fire',
    spellList: 'scalders',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Inflict [two] points of damage on each army at that terrain.'
  }, {
    id: 'flashfire',
    title: 'Flashfire',
    element: 'fire',
    spellList: 'firewalkers',
    cost: 3,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: true,
    description: 'Target any army. During any non-maneuver army roll, any [one] unit in the target army may be re-rolled once, ignoring the previous result. This effect lasts until the beginning of your next turn.'
  }, {
    id: 'fiery-weapon',
    title: 'Fiery Weapon',
    element: 'fire',
    cost: 4,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target any army. Add [two] melee or missile results to any roll the target makes until the beginning of your next turn.'
  }, {
    id: 'dancing-lights',
    title: 'Dancing Lights',
    element: 'fire',
    cost: 6,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any opposing army. Subtract [six] melee results from the target’s rolls until the beginning of your next turn.'
  }, // Elemental =================================================================
  {
    id: 'evolve-dragonkin',
    title: 'Evolve Dragonkin',
    element: 'elemental',
    spellList: 'eladrim',
    cost: 3,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target one of your Dragonkin units that matches the color of magic used to cast this spell. The target is promoted [one] health-worth.'
  }, {
    id: 'resurrect-dead',
    title: 'Resurrect Dead',
    element: 'elemental',
    cost: 3,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target [one] health-worth of units in your DUA that contains the color of magic used to cast this spell. Return the target(s) to the casting army. Magic of any one color (or Ivory) may be used to resurrect Amazons.'
  }, {
    id: 'esfahs-gift',
    title: 'Esfah’s Gift',
    element: 'elemental',
    spellList: 'amazons',
    cost: 3,
    multiplicative: false,
    castFromReserves: true,
    castableWithCantrip: true,
    description: 'Target a minor terrain in your BUA. Move that terrain to your summoning pool.'
  }, {
    id: 'summon-dragonkin',
    title: 'Summon Dragonkin',
    element: 'elemental',
    cost: 3,
    multiplicative: true,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target one health-worth of Dragonkin units in your Summoning Pool that match the color of magic used to cast this spell. The target(s) join the casting army.'
  }, {
    id: 'rally',
    title: 'Rally',
    element: 'elemental',
    spellList: 'amazons',
    cost: 5,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target up to [three] of your Amazon units at a terrain. Move those units to any other terrain where you have at least one Amazon unit.'
  }, {
    id: 'rise-of-the-eladrim',
    title: 'Rise of the Eladrim',
    element: 'elemental',
    spellList: 'eladrim',
    cost: 5,
    multiplicative: true,
    castFromReserves: true,
    castableWithCantrip: false,
    description: 'Target any Eldarim unit that matches the color of magic used to cast this spell. The target is promoted [one] health-worth.'
  }, {
    id: 'summon-dragon',
    title: 'Summon Dragon',
    element: 'elemental',
    cost: 7,
    multiplicative: false,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Summon one dragon from any Summoning Pool or terrain that contains the color used to cast this spell to the target terrain. Magic of any one color may be used to summon an Ivory or Ivory Hybrid Dragon.'
  }, {
    id: 'summon-white-dragon',
    title: 'Summon White Dragon',
    element: 'elemental',
    cost: 14,
    multiplicative: false,
    castFromReserves: false,
    castableWithCantrip: false,
    description: 'Target any terrain. Summon one White Dragon from any Summoning Pool or terrain to the target terrain. Any combination of magic colors may be used to cast this spell.'
  }];
});