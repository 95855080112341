define("dragon-dice/services/sais", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Service {
    mapSai(id) {
      let sai = sais.findBy('id', id);

      if (!sai) {
        console.warn('unknown sai map:', id);
        return {
          type: '(Unknown)'
        };
      }

      return sai;
    }

  }

  _exports.default = _default;
  let sais = [{
    id: 'attune',
    title: 'Attune',
    phases: ['Magic'],
    description: 'During a magic action, Attune generates X magic results of any color. Attune may also count the normal (non-ID, non-SAI) magic results of one unit in the marching army as the same color.'
  }, {
    id: 'bash',
    title: 'Bash',
    phases: ['Dragon Attack', 'Save'],
    description: 'During a save roll against a melee attack, target one unit from the attacking army. The targeted unit takes damage equal to the melee results it generated. The targeted unit must make a save roll against this damage. Bash also generates save results equal to the targeted unit’s melee results. During other save rolls, Bash generates X save results. During a dragon attack choose an attacking dragon that has inflicted damage. That dragon takes damage equal to the amount of damage it inflicted. Bash also generates save results equal to the damage the chosen dragon did.'
  }, {
    id: 'belly',
    title: 'Belly',
    phases: ['Any Phase'],
    description: 'During any roll, the unit loses its automatic save results.'
  }, {
    id: 'breath',
    title: 'Breath',
    phases: ['Melee'],
    description: 'During a melee attack, target X health-worth of units in the defending army. The targets are killed.'
  }, {
    id: 'bullseye',
    title: 'Bullseye',
    phases: ['Dragon Attack', 'Missile'],
    description: 'During a missile attack, target X health-worth of units in the defending army. The targets make a save roll. Those that do not generate a save result are killed. Roll this unit again and apply the new result as well. During a dragon attack, Bullseye generates X missile results.'
  }, {
    id: 'cantrip',
    title: 'Cantrip',
    phases: ['Any Non-Maneuver'],
    description: 'During a magic action or Magic Negation roll, Cantrip generates X magic results. During other non-maneuver rolls, Cantrip generates X magic results that only allow you to cast spells marked as ‘Cantrip’ from the spell list.'
  }, {
    id: 'charge',
    title: 'Charge',
    phases: ['Melee'],
    description: 'During a melee attack, the attacking army counts all Maneuver results as if they were Melee results. Instead of making a regular save roll or a counter-attack, the defending army makes a combination save and melee roll. The attacking army takes damage equal to these melee results. Only save results generated by spells may reduce this damage. Charge has no effect during a counter-attack.'
  }, {
    id: 'charm',
    title: 'Charm',
    phases: ['Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army; those units don’t roll to save during this march. Instead, the owner rolls these units and adds their results to the attacking army’s results. Those units may take damage from the melee attack as normal.'
  }, {
    id: 'choke',
    title: 'Choke',
    phases: ['Melee'],
    description: 'During a melee attack, when the defending army rolls for saves, target up to X health-worth of units in the that army that rolled an ID icon. The targets are killed. None of their results are counted towards the army’s save results. Note: Choke works outside of the normal sequence of die roll resolution, applying its effect immediately after the defending army makes its save roll but before they resolve any re-roll effects or SAIs.'
  }, {
    id: 'cloak',
    title: 'Cloak',
    phases: ['Dragon Attack', 'Individual Roll', 'Magic', 'Melee', 'Missile', 'Save'],
    description: 'During a save roll or dragon attack, add X non-magical save results to the army containing this unit until the beginning of your next turn. During a magic action, Cloak generates X magic results. During a roll for an individual-targeting effect, Cloak generates X magic, maneuver, melee, missile, or save results.'
  }, {
    id: 'coil',
    title: 'Coil',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a melee attack, target one unit in the defending army. The target takes X damage and makes a combination roll, counting save and melee results. Any melee results that the target generates inflict damage on the Coiling unit with no save possible. During a dragon attack, Coil generates X melee results.'
  }, {
    id: 'confuse',
    title: 'Confuse',
    phases: ['Melee', 'Missile'],
    description: 'During a melee or missile attack, target up to X health-worth of units in the defending army after they have rolled for saves. Re-roll the targeted units, ignoring all previous results. Note: Confuse works outside of the normal sequence of die roll resolution, applying its effect immediately after the defending army makes its save roll but before they resolve any re-roll effects or SAIs.'
  }, {
    id: 'convert',
    title: 'Convert',
    phases: ['Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army. The targets make a save roll. Those that do not generate a save result are killed. The attacking player may return up to the amount of heath-worth killed this way from their DUA to the attacking army.'
  }, {
    id: 'counter',
    title: 'Counter',
    phases: ['Dragon Attack', 'Melee', 'Save vs. Melee'],
    description: 'During a save roll against a melee attack, Counter generates X save results and inflicts X damage upon the attacking army. Only save results generated by spells may reduce this damage. During any other save roll, Counter generates X save results. During a melee attack, Counter generates X melee results. During a dragon attack, Counter generates X save and X melee results.'
  }, {
    id: 'createFireminions',
    title: 'Create Fireminions',
    phases: ['Any Non-Individual'],
    description: 'During any army roll, Create Fireminions generates X magic, maneuver, melee, missile, or save results.'
  }, {
    id: 'crush',
    title: 'Crush',
    phases: ['Dragon Attack', 'Missile'],
    description: 'During a missile attack, target up to X-health worth of units in the defending army. The targets make a maneuver roll. Those that do not generate a maneuver result are killed. Each unit killed must make a save roll. Those that do not generate a save result on this second roll are buried. During a dragon attack, Crush generates X missile results.'
  }, {
    id: 'decapitate',
    title: 'Decapitate',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a melee attack, when the defending army makes its save roll, target one unit that rolled an ID icon. The target is killed. None of their results are counted towards the army’s save results. Note: Decapitate works outside of the normal sequence of die roll resolution, applying its effect immediately after the defending army makes its save roll but before they resolve any re-roll effects or SAIs. During a dragon attack, kill one dragon that rolled Jaws. If no dragon rolled Jaws, Decapitate generates three melee results.'
  }, {
    id: 'dispelMagic',
    title: 'Dispel Magic',
    phases: ['Special'],
    description: 'Whenever any magic targets this unit, the army containing this unit or the terrain this unit occupies, you may roll this unit after all spells are announced but before any are resolved. If the Dispel Magic icon is rolled, negate all unresolved magic that targets or effects this unit, its army or the terrain it occupies. No other icons have any effect during this special roll. Magic targeting other units, armies, or terrains is unaffected by this SAI.'
  }, {
    id: 'doubleStrike',
    title: 'Double Strike',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a melee attack, target four health-worth of units in the defending army. The targets make a save roll. Those that do not generate a save result are killed. Roll this unit again and apply the new result as well. During a dragon attack, Double Strike generates four melee results.'
  }, {
    id: 'elemental-relic',
    title: 'Elemental Relic',
    phases: ['Non-Maneuver'],
    description: 'During any non-maneuver roll, X health-worth of matching units in the army carrying this relic may be re-rolled once, ignoring the previous result, or you may recruit X small (1 health) matching units to, or promote X health-worth of matching units in, the army carrying this relic. Results may be split between recruiting, promoting, and re-rolling in any way you choose. Any promotions and recruitments happen all at once.'
  }, {
    id: 'elevate',
    title: 'Elevate',
    phases: ['Dragon Attack', 'Maneuver', 'Missile', 'Save'],
    description: 'During a maneuver roll, Elevate generates X maneuver results. During a missile attack, double one unit’s missile results. During a save roll against a melee attack, double one unit’s save results. During a dragon attack, double one unit’s missile or save results.'
  }, {
    id: 'entangle',
    title: 'Entangle',
    phases: ['Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army. The targets are killed.'
  }, {
    id: 'ferry',
    title: 'Ferry',
    phases: ['Non-Maneuver'],
    description: 'During any non-maneuver roll, the Ferrying unit may move itself and up to four health-worth of units in its army to any terrain.'
  }, {
    id: 'firebreath',
    title: 'Firebreath',
    phases: ['Melee'],
    description: 'During a melee attack, inflict X points of damage on the defending army with no save possible. Each unit killed makes a save roll. Those that do not generate a save result are buried.'
  }, {
    id: 'firecloud',
    title: 'Firecloud',
    phases: ['Missile'],
    description: 'During a melee or missile attack, target up to X health-worth of units in the defending army. The targets make a maneuver roll. Those that do not generate a maneuver result are killed.'
  }, {
    id: 'firewalking',
    title: 'Firewalking',
    phases: ['Any Phase'],
    description: 'During a maneuver roll, Firewalking generates X maneuver results. During any non-maneuver roll, this unit may move itself and up to three health-worth of units in its army to any terrain.'
  }, {
    id: 'flame',
    title: 'Flame',
    phases: ['Melee'],
    description: 'During a melee attack, target up to two health-worth of units in the defending army. The targets are killed and buried.'
  }, {
    id: 'flamingArrow',
    title: 'Flaming Arrow',
    phases: ['Dragon Attack', 'Missile'],
    description: 'During a missile attack, target X health-worth of units in the defending army. The targets make a save roll. Those that do not generate a save result are killed. Each unit killed must make another save roll. Those that do not generate a save result on this second roll are buried. During a dragon attack, Flaming Arrow generates X missile results.'
  }, {
    id: 'flurry',
    title: 'Flurry',
    phases: ['Dragon Attack', 'Melee', 'Save'],
    description: 'During a melee attack, Flurry generates X melee results. Roll the item again and apply the new result as well. During a save roll against a melee attack, Flurry generates X save results. During a dragon attack, Flurry generates either X save results, or X melee results. If it generates melee results, roll the item again and apply the new result as well.'
  }, {
    id: 'fly',
    title: 'Fly',
    phases: ['Any Phase'],
    description: 'During any roll, Fly generates X maneuver or X save results.'
  }, {
    id: 'frostBreath',
    title: 'Frost Breath',
    phases: ['Melee', 'Missile'],
    description: 'During a melee or missile attack, target an opposing army at the same terrain. Until the beginning of your next turn, the target army halves all results they roll.'
  }, {
    id: 'galeforce',
    title: 'Galeforce',
    phases: ['Magic', 'Melee', 'Missile'],
    description: 'During a melee or missile attack, or a magic action at a terrain, target an opposing army at any terrain. Until the beginning of your next turn, the target army subtracts four save and four maneuver results from all rolls.'
  }, {
    id: 'gore',
    title: 'Gore',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a melee attack, target one unit in the defending army. The target takes two points of damage. If the unit is killed by Gore, it is then buried. During a dragon attack, Gore generates four melee results.'
  }, {
    id: 'hoof',
    title: 'Hoof',
    phases: ['Dragon Attack', 'Maneuver', 'Save'],
    description: 'During a maneuver roll, Hoof generates X maneuver results. During a save roll, Hoof generates X save results. During a dragon attack, Hoof generates X save results.'
  }, {
    id: 'howl',
    title: 'Howl',
    phases: ['Melee', 'Missile'],
    description: 'During a melee or missile attack, the defending army subtracts X save results.'
  }, {
    id: 'hug',
    title: 'Hug',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a melee attack, target one unit in the defending army. The target unit takes X points of damage with no save possible. The targeted unit makes a melee roll. Melee results generated by this roll inflict damage on the Hugging unit with no save possible. During a dragon attack, Hug generates X melee results.'
  }, {
    id: 'hypnoticGlare',
    title: 'Hypnotic Glare',
    phases: ['Melee'],
    description: 'During a melee attack, when the defending army rolls for saves, all units that roll an ID icon are hypnotized and may not be rolled until the beginning of your next turn. None of their results are counted towards the army’s save results. The effect ends if the glaring unit leaves the terrain, is killed, or is rolled. The glaring unit may be excluded from any roll until the effect expires. Note: Hypnotic Glare works outside of the normal sequence of die roll resolution, applying its effect immediately after the defending army makes its save roll but before they resolve any re-roll effects or SAIs.'
  }, {
    id: 'illusion',
    title: 'Illusion',
    phases: ['Magic', 'Melee', 'Missile'],
    description: 'During a magic, melee or missile attack, target any of your armies. Until the beginning of your next turn, the target army cannot be targeted by any missile attacks or spells cast by opposing players.'
  }, {
    id: 'impale',
    title: 'Impale',
    phases: ['Dragon Attack', 'Missile'],
    description: 'During a missile attack, when the defending army makes their save roll, target one unit that rolled an ID icon. The target is killed. None of their results are counted towards the army’s save results. Note: Impale works outside of the normal sequence of die roll resolution, applying its effect immediately after the defending army makes its save roll but before they resolve any re-roll effects or SAIs. During a dragon attack, kill one dragon that rolled Jaws. If no dragon rolled Jaws, Impale generates three missile results.'
  }, {
    id: 'kick',
    title: 'Kick',
    phases: ['Dragon Attack', 'Melee', 'Save'],
    description: 'During a melee attack, target one unit in the defending army. The target takes X points of damage. During a save roll, Kick generates X save results. During a dragon attack, Kick generates X melee and X save results.'
  }, {
    id: 'dragonkin-champion-logo',
    title: 'Logo',
    phases: ['Individual Save', 'Melee'],
    description: 'When saving against an individual targeting effect, the SFR/TSR logo generates four save results. During a melee attack, you may move a summoned dragon that contains the same color as this Dragonkin Champion from one terrain to another.'
  }, {
    id: 'net',
    title: 'Net',
    phases: ['Individual Save', 'Melee', 'Missile'],
    description: 'During a melee or missile attack, target up to X health-worth of units in the defending army. Each targeted unit makes a maneuver roll. Those that do not generate a maneuver result are netted and may not be rolled or leave the terrain they currently occupy until the beginning of your next turn. Net does nothing during a missile attack targeting an opponent’s Reserve Army from a Tower on its eighth face. When saving against an individual targeting effect, Net generates X save results.'
  }, {
    id: 'plague',
    title: 'Plague',
    phases: ['Melee'],
    description: 'During a melee attack, target one unit in the defending army. The target makes a save roll. If the target fails to generate a save result it is killed. That unit’s owner then targets another unit in the same army to save or be killed. Continue to target units in this way until a targeted unit generates a save result.'
  }, {
    id: 'poison',
    title: 'Poison',
    phases: ['Melee'],
    description: 'During a melee attack, target X health-worth of units in the defending army. Each targeted unit makes a save roll. Those that do not generate a save result are killed and must make another save roll. Those that do not generate a save result on this second roll are buried.'
  }, {
    id: 'regenerate',
    title: 'Regenerate',
    phases: ['Any Non-Maneuver'],
    description: 'During any non-maneuver roll, choose one: Regenerate generates X save results, OR, you may bring back up to X health-worth of units from your DUA to the army containing this unit.'
  }, {
    id: 'rend',
    title: 'Rend',
    phases: ['Dragon Attack', 'Maneuver', 'Melee'],
    description: 'During a melee or dragon attack, Rend generates X melee results. Roll this unit again and apply the new result as well. During a maneuver roll, Rend generates X maneuver results.'
  }, {
    id: 'riseFromTheAshes',
    title: 'Rise from the Ashes',
    phases: ['Save', 'Special'],
    description: 'During a save roll, Rise from the Ashes generates X save results. Whenever a unit with this SAI is killed or buried, roll the unit. If Rise from the Ashes is rolled, the unit is moved to your Reserve Area. If an effect both kills and buries this unit, it may roll once when killed and again when buried. If the first roll is successful, the unit is not buried.'
  }, {
    id: 'roar',
    title: 'Roar',
    phases: ['Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army. The targets are immediately moved to their Reserve Area before the defending army rolls for saves.'
  }, {
    id: 'scare',
    title: 'Scare',
    phases: ['Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army. The targets make a save roll. Those that do not generate a save result are immediately moved to their Reserve Area before the defending army rolls for saves. Those that roll their ID icon are killed.'
  }, {
    id: 'screech',
    title: 'Screech',
    phases: ['Melee'],
    description: 'During a melee attack, the defending army subtracts X save results.'
  }, {
    id: 'seize',
    title: 'Seize',
    phases: ['Missile'],
    description: 'During a melee or missile attack, target up to X health-worth of units in the defending army. Roll the targets. If they roll an ID icon, they are immediately moved to their Reserve Area. Any that do not roll an ID are killed.'
  }, {
    id: 'slay',
    title: 'Slay',
    phases: ['Melee'],
    description: 'During a melee attack, target one unit in the defending army. Roll the target. If it does not roll its ID icon, it is killed.'
  }, {
    id: 'sleep',
    title: 'Sleep',
    phases: ['Melee'],
    description: 'During a melee attack, target one unit in an opponent’s army at this terrain. The target unit is asleep and cannot be rolled or leave the terrain they currently occupy until the beginning of your next turn.'
  }, {
    id: 'smite',
    title: 'Smite',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a melee attack, Smite inflicts X points of damage to the defending army with no save possible. During a dragon attack, Smite generates X melee results.'
  }, {
    id: 'smother',
    title: 'Smother',
    phases: ['Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army. The targets make a maneuver roll. Those that do not generate a maneuver result are killed.'
  }, {
    id: 'sneakAttack',
    title: 'Sneak Attack',
    phases: ['Dragon Attack', 'Magic', 'Melee', 'Missile'],
    description: 'During a missile attack or magic action, Sneak Attack inflicts X damage on an opposing army at this terrain with no save possible. Dragonkin killed by this damage are buried. During a melee or dragon attack, Sneak Attack generates X melee results.'
  }, {
    id: 'sortie',
    title: 'Sortie',
    phases: ['Dragon Attack', 'Melee', 'Save'],
    description: 'During a melee attack, Sortie generates X melee results. During a save roll, Sortie generates X save results. During a dragon attack, Sortie generates X save and X melee results.'
  }, {
    id: 'stomp',
    title: 'Stomp',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army. The targets make a maneuver roll. Those that do not generate a maneuver result are killed and must make a save roll. Those that do not generate a save result are buried. During a dragon attack, Stomp generates X melee results.'
  }, {
    id: 'stone',
    title: 'Stone',
    phases: ['Dragon Attack', 'Melee', 'Missile'],
    description: 'During a melee or missile attack, Stone does X damage to the defending army with no save possible. During a dragon attack, Stone generates X missile results.'
  }, {
    id: 'stun',
    title: 'Stun',
    phases: ['Melee'],
    description: 'During a melee attack, target up to X health-worth of units in the defending army. The targets make a maneuver roll. Those that do not generate a maneuver result are stunned and cannot be rolled until the beginning of your turn, unless they are the target of an individual-targeting effect which forces them to. Stunned units that leave the terrain through any means are no longer stunned. Roll this unit again and apply the new result as well.'
  }, {
    id: 'summonDragon',
    title: 'Summon Dragon',
    phases: ['Magic'],
    description: 'During a magic action at a terrain, target any dragon that contains the color of this item, or an Ivory Dragon, and summon it to any terrain. Summon Dragon may not be used to summon a White Dragon. Roll this die again and apply the new result as well. During a magic action in reserves, Summon Dragon generates X magic results.'
  }, {
    id: 'surprise',
    title: 'Surprise',
    phases: ['Melee'],
    description: 'During a melee attack, the defending army cannot counter-attack. The defending army may still make a save roll as normal. Surprise has no effect during a counter-attack.'
  }, {
    id: 'swallow',
    title: 'Swallow',
    phases: ['Melee'],
    description: 'During a melee attack, target one unit in the defending army. Roll the target. If it does not roll its ID icon, it is killed and buried.'
  }, {
    id: 'tail',
    title: 'Tail',
    phases: ['Dragon Attack', 'Melee'],
    description: 'During a dragon or melee attack, Tail generates two melee results. Roll this unit again and apply the new result as well.'
  }, {
    id: 'teleport',
    title: 'Teleport',
    phases: ['Any Phase'],
    description: 'During a maneuver roll, Teleport generates X maneuver results. During any non-maneuver roll, this unit may move itself and up to three health-worth of units in its army to any terrain.'
  }, {
    id: 'trample',
    title: 'Trample',
    phases: ['Any Phase'],
    description: 'During any roll, Trample generates X maneuver and X melee results.'
  }, {
    id: 'trumpet',
    title: 'Trumpet',
    phases: ['Dragon Attack', 'Melee', 'Save'],
    description: 'During a dragon attack, melee attack or save roll, each Feral unit in this army doubles its melee and save results.'
  }, {
    id: 'vanish',
    title: 'Vanish',
    phases: ['Save'],
    description: 'During a save roll, Vanish generates X save results. The unit may then move to any terrain or its Reserve Area. If the unit moves, the save results still apply to the army that the Vanishing unit left.'
  }, {
    id: 'volley',
    title: 'Volley',
    phases: ['Dragon Attack', 'Missile', 'Save', 'Save vs. Missile'],
    description: 'During a save roll against a missile attack, Volley generates X save and inflicts X damage upon the attacking army. Only save results generated by spells may reduce this damage. During any other save roll, Volley generates X save results. During a missile attack, Volley generates X missile results. During a dragon attack, Volley generates X save and X missile results.'
  }, {
    id: 'wave',
    title: 'Wave',
    phases: ['Maneuver', 'Melee'],
    description: 'During a melee attack, the defending army subtracts X from their save results. During a maneuver roll while marching, subtract X from each counter-maneuvering army’s maneuver results. Wave does nothing if rolled during a counter-maneuver.'
  }, {
    id: 'wayfare',
    title: 'Wayfare',
    phases: ['Dragon Attack', 'Maneuver'],
    description: 'During a maneuver roll, Wayfare generates X maneuver results. Instead of generating maneuver results, the item and one unit able to carry it may move to any other terrain or your Reserve Area. During a dragon attack, Wayfare allows the item and a unit able to carry it to move to any terrain or your Reserve Area.'
  }, {
    id: 'web',
    title: 'Web',
    phases: ['Melee'],
    description: 'During a melee or missile attack, target up to X health-worth of units in the defending army. The targets make a melee roll. Those that do not generate a melee result are webbed and cannot be rolled or leave the terrain they currently occupy until the beginning of your next turn. Web does nothing during a missile action targeting an opponent’s Reserve Army from a Tower on its eighth-face.'
  }, {
    id: 'wildGrowth',
    title: 'Wild Growth',
    phases: ['Any Non-Maneuver'],
    description: 'During any non-maneuver roll, Wild Growth generates X save results or allows you to promote X health-worth of units in this army. Results may be split between saves and promotions in any way you choose. Any promotions happen all at once.'
  }, {
    id: 'wither',
    title: 'Wither',
    phases: ['Melee'],
    description: 'During a melee attack, target any opposing army at the same terrain. Until the beginning of your next turn, the targeted army subtracts X results from all rolls it makes.'
  }, {
    id: 'dragon-belly',
    title: 'Belly',
    phases: [],
    description: 'The dragon’s automatic saves do not count during this attack.'
  }, {
    id: 'dragon-breath',
    title: 'Breath',
    phases: [],
    description: 'Dragon breath effects are based on the dragon’s color.'
  }, {
    id: 'dragon-claw',
    title: 'Claw',
    phases: [],
    description: 'A dragon’s claws inflict six points of damage.'
  }, {
    id: 'dragon-jaws',
    title: 'Jaws',
    phases: [],
    description: 'A dragon’s jaws inflict twelve points of damage.'
  }, {
    id: 'dragon-tail',
    title: 'Tail',
    phases: [],
    description: 'The dragon’s tail inflicts three points of damage; roll the dragon again and apply the new result as well.'
  }, {
    id: 'dragon-treasure',
    title: 'Treasure',
    phases: [],
    description: 'If the dragon is attacking an army, one unit in that army may be promoted.'
  }, {
    id: 'dragon-wing',
    title: 'Wing',
    phases: [],
    description: 'A dragon’s wings inflict five points of damage. After the attack, if the dragon is still alive, it flies away. Return the dragon to its owner’s Summoning Pool.'
  }];
});