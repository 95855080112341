define("dragon-dice/pods/index/armies/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let _class = (_class2 = class extends Ember.Controller {
    async createArmy() {
      try {
        let newArmy = this.store.createRecord('army', {
          name: 'New Army'
        });
        await newArmy.save();
        this.transitionToRoute('army.index', newArmy.id, {
          queryParams: {
            searchBy: 'all'
          }
        });
      } catch (ex) {
        console.error(ex);
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "createArmy", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "createArmy"), _class2.prototype)), _class2);

  _exports.default = _class;
});