define("dragon-dice/pods/components/modals/x-add-to-army/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    newArmy: null,

    async init() {
      this._super(...arguments);

      let armies = await this.store.findAll('army');
      this.setProperties({
        armies,
        newArmy: null
      });
    },

    // TODO: This was changed from `armies.@each.allUnits.@each.id`; need to verify it still works.
    filteredArmies: Ember.computed('armies.@each.allUnits', 'unit.id', function () {
      if (!this.unit) {
        return;
      }

      return this.armies.reject(army => army.allUnits.mapBy('id').includes(this.unit.id));
    })
  });

  _exports.default = _default;
});