define("dragon-dice/pods/species/breath/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model() {
      return [{
        title: 'Dragon Plague',
        spellList: 'Dragons',
        element: 'death',
        description: 'The army ignores all of its ID results until the beginning of its next turn.'
      }, {
        title: 'Lightning Bolt',
        spellList: 'Dragons',
        element: 'air',
        description: 'The army’s melee results are halved until the beginning of its next turn. Results are rounded down.'
      }, {
        title: 'Petrify',
        spellList: 'Dragons',
        element: 'earth',
        description: 'The army’s maneuver results are halved until the beginning of its next turn. Results are rounded down.'
      }, {
        title: 'Poisonous Cloud',
        spellList: 'Dragons',
        element: 'water',
        description: 'The army’s missile results are halved until the beginning of its next turn. Results are rounded down.'
      }, {
        title: 'Dragon Fire',
        spellList: 'Dragons',
        element: 'fire',
        description: 'Roll the units killed by this dragon’s breath attack. Those that do not generate a save result are buried.'
      }, {
        title: 'Life Drain',
        spellList: 'Dragons',
        element: 'ivory'
      }, {
        title: 'Terrain Empathy',
        spellList: 'Dragons',
        element: 'white',
        description: 'An additional five health-worth of units in the army are killed. The army suffers the colored breath effects of both colors of the terrain.'
      }];
    }

  }

  _exports.default = _default;
});