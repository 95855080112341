define("dragon-dice/pods/components/sai-face/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['species'],
    faceUrl: Ember.computed('species', 'action', function () {
      let suffix = this.species === 'dragons' ? 'd' : 'm';
      return `/images/faces/${this.species}/face-${this.action}-${suffix}.svg`;
    })
  });

  _exports.default = _default;
});