define("dragon-dice/pods/species/units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C/6ZwkYz",
    "block": "{\"symbols\":[\"details\",\"breakdown\",\"part\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-left\"],[8],[0,\"\\n\"],[4,\"species-breakdown\",null,[[\"units\",\"groupBy\",\"groupByOptions\",\"onAddToArmy\",\"shouldOverflow\",\"overflowContainer\"],[[24,[\"model\",\"species\",\"units\"]],[24,[\"groupBy\"]],[24,[\"groupByOptions\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showAddToArmy\"]]],null]],null],[28,\"media\",[\"twoColumn\"],null],\"overflow\"]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"details\"]],\"expected `breakdown.details` to be a contextual component but found a string. Did you mean `(component breakdown.details)`? ('dragon-dice/pods/species/units/template.hbs' @ L8:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[1,[23,3,[\"faces\"]],false],[0,\"\\n      \"],[1,[23,3,[\"chances\"]],false],[0,\"\\n      \"],[1,[23,3,[\"averages\"]],false],[0,\"\\n      \"],[1,[23,3,[\"army\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"section-right\"],[8],[0,\"\\n  \"],[5,\"from-elsewhere\",[],[[\"@name\",\"@tagName\"],[\"overflow\",\"ul\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"component\",[[23,1,[]]],null],false],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"modals/x-add-to-army\",null,[[\"unit\",\"isShown\",\"onSubmit\",\"onClose\"],[[24,[\"showAddToArmy\"]],[28,\"not\",[[28,\"not\",[[24,[\"showAddToArmy\"]]],null]],null],[28,\"action\",[[23,0,[]],\"addToArmy\",[24,[\"showAddToArmy\",\"id\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showAddToArmy\"]]],null],null],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species/units/template.hbs"
    }
  });

  _exports.default = _default;
});