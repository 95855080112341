define("dragon-dice/pods/components/face-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':background-face', 'species'],
    faceType: Ember.computed('face.{type,action}', function () {
      let {
        action,
        type
      } = this.face;

      if (type === 'id') {
        return 'ID';
      } else if (action) {
        return action;
      } else {
        return type;
      }
    }),
    faceUrl: Ember.computed('unit.{rarity, type, species}', function () {
      let {
        species,
        rarity,
        type
      } = this.unit;
      return `/images/faces/${species}/id-${type}-${rarity}.svg`;
    })
  });

  _exports.default = _default;
});