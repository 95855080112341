define("dragon-dice/pods/components/face-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'figure',
    role: 'button',
    attributeBindings: ['role'],
    classNameBindings: ['expanded', 'isEditing:editing'],

    click() {
      if (this.onClick) {
        this.onClick();
      }
    }

  });

  _exports.default = _default;
});