define("dragon-dice/tests/factories/army", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('army', {
    traits: {
      'with-unit': {
        units: [{
          id: 'evoker',
          qty: 1
        }]
      },
      'with-units': {
        units: [{
          id: 'protector',
          qty: 1
        }, {
          id: 'spriteSwarm',
          qty: 1
        }, {
          id: 'thaumaturgist',
          qty: 1
        }]
      },
      'with-unit-with-sai': {
        units: [{
          id: 'unicorn',
          qty: 1
        }]
      }
    }
  });
});