define("dragon-dice/pods/species-dragons/sais/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lYAxpy63",
    "block": "{\"symbols\":[\"sai\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"one-column\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"Faces\"],[9],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"list-icons\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[7,\"figure\",false],[12,\"role\",\"button\"],[12,\"class\",[28,\"if\",[[23,1,[\"expanded\"]],\"open\"],null]],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"expanded\",[23,1,[]]],null]]],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"icon-wrapper\"],[8],[0,\"\\n            \"],[1,[28,\"sai-face\",null,[[\"species\",\"action\"],[[24,[\"species\"]],[23,1,[\"id\"]]]]],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"figcaption\",true],[8],[0,\"\\n            \"],[7,\"h4\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"phases\"]]],null,{\"statements\":[[0,\"              \"],[7,\"span\",true],[10,\"class\",\"subtitle\"],[8],[1,[28,\"join\",[\", \",[23,1,[\"phases\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[9],[0,\"\\n          \"],[1,[28,\"i\",[\"norgie\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"liquid-if\",[[23,1,[\"expanded\"]]],[[\"class\"],[\"article\"]],{\"statements\":[[0,\"          \"],[7,\"p\",true],[8],[0,\"\\n            \"],[1,[23,1,[\"description\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species-dragons/sais/template.hbs"
    }
  });

  _exports.default = _default;
});