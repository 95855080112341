define("dragon-dice/pods/components/unit-details/unit-averages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "INQAqjzd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h5\",true],[8],[0,\"Average Result \"],[7,\"span\",true],[10,\"class\",\"de-em\"],[8],[0,\"(Standard Deviation)\"],[9],[9],[0,\"\\n\"],[1,[28,\"graphs/graph-average\",null,[[\"data\",\"barValue\",\"stdValue\",\"format\",\"species\"],[[24,[\"unit\",\"faceBreakdown\"]],\"average\",\"stDev\",\"0.000\",[24,[\"unit\",\"species\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/unit-details/unit-averages/template.hbs"
    }
  });

  _exports.default = _default;
});