define("dragon-dice/pods/components/sai-face/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vSksp1mZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"background-face \",[28,\"if\",[[28,\"eq\",[[24,[\"face\",\"type\"]],\"id\"],null],\"id\"],null]]]],[8],[0,\"\\n  \"],[7,\"svg\",true],[8],[0,\"\\n    \"],[7,\"use\",true],[11,\"xlink:href\",[29,[[22,\"faceUrl\"],\"#a\"]],\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/sai-face/template.hbs"
    }
  });

  _exports.default = _default;
});