define("dragon-dice/pods/components/species-breakdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z8cZzNPw",
    "block": "{\"symbols\":[\"type\",\"unit\"],\"statements\":[[4,\"each\",[[24,[\"sortedTypes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[1,[28,\"unslugify\",[[23,1,[\"value\"]]],null],false],[9],[0,\"\\n  \"],[7,\"ol\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"items\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"data-test-breakdown-unit\",[23,2,[\"id\"]]],[8],[0,\"\\n        \"],[1,[28,\"unit-overview\",null,[[\"unit\",\"isEditing\",\"isSearching\",\"onUnitSelected\",\"onUnitQtyChanged\",\"onAddToArmy\",\"overflowContainer\",\"shouldOverflow\"],[[23,2,[]],[24,[\"isEditing\"]],[24,[\"isSearching\"]],[28,\"action\",[[23,0,[]],\"unitSelected\"],null],[28,\"optional\",[[24,[\"onUnitQtyChanged\"]]],null],[28,\"optional\",[[24,[\"onAddToArmy\"]]],null],[24,[\"overflowContainer\"]],[24,[\"shouldOverflow\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[28,\"and\",[[28,\"not\",[[24,[\"selectedUnit\"]]],null],[24,[\"army\",\"allUnits\",\"length\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[[24,[\"overflowContainer\"]],[28,\"component\",[\"unit-details\"],[[\"unit\",\"slide\",\"onAddToArmy\",\"class\"],[[24,[\"army\"]],[28,\"not\",[[24,[\"shouldOverflow\"]]],null],[28,\"optional\",[[24,[\"onAddToArmy\"]]],null],[28,\"concat\",[\"element--\",[24,[\"army\",\"color\"]]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/species-breakdown/template.hbs"
    }
  });

  _exports.default = _default;
});