define("dragon-dice/pods/components/species-breakdown/component", ["exports", "dragon-dice/utils/group-by"], function (_exports, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const typeSortOrder = ['heavy', 'light', 'cavalry', 'missile', 'magic', 'monster', 'champion'];

  var _default = Ember.Component.extend({
    classNameBindings: ['isEditing:editing'],
    groupBy: 'type',
    isEditing: false,
    unitsByRarity: Ember.computed('units.@each.health', function () {
      return this.units.sortBy('health');
    }),
    unitsByType: Ember.computed('unitsByRarity.@each.type', 'groupBy', function () {
      return (0, _groupBy.default)(this.unitsByRarity, this.groupBy);
    }),
    sortedTypes: Ember.computed('unitsByType', function () {
      return this.unitsByType.sort((a, b) => typeSortOrder.indexOf(a.value) - typeSortOrder.indexOf(b.value));
    }),
    selectedUnit: Ember.computed('units.@each.expanded', function () {
      return this.units.findBy('expanded', true);
    }),
    actions: {
      unitSelected(unit) {
        if (this.selectedUnit === unit) {
          this.units.setEach('expanded', false);
        } else {
          this.units.forEach(u => Ember.set(u, 'expanded', u === unit));
        }
      }

    }
  });

  _exports.default = _default;
});