define("dragon-dice/pods/components/input-segmented/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tckMEkV5",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"selected\"]]],null],\"active\"],null]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"onChange\"]]],null],[23,1,[]]]],[8],[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/input-segmented/template.hbs"
    }
  });

  _exports.default = _default;
});