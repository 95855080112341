define("dragon-dice/pods/components/graphs/graph-average/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5S/kp8MC",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[7,\"dl\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"graphData\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"chart-row\"],[8],[0,\"\\n      \"],[7,\"dt\",true],[8],[1,[23,1,[\"type\"]],false],[9],[0,\"\\n      \"],[7,\"dd\",true],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"chart-bar\"],[11,\"style\",[23,1,[\"style\"]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"stdPercentage\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"chart-std\"],[11,\"style\",[23,1,[\"stdStyle\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"chart-value\"],[8],[0,\"\\n          \"],[1,[28,\"format-number\",[[28,\"get\",[[23,1,[]],[24,[\"barValue\"]]],null]],[[\"format\"],[[24,[\"format\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"stdValue\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[10,\"class\",\"de-em\"],[8],[0,\"(±\"],[1,[28,\"format-number\",[[28,\"get\",[[23,1,[]],[24,[\"stdValue\"]]],null]],[[\"format\"],[[24,[\"format\"]]]]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/graphs/graph-average/template.hbs"
    }
  });

  _exports.default = _default;
});