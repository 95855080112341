define("dragon-dice/services/data", ["exports", "dragon-dice/data/species/dragonkin", "dragon-dice/data/species/dragons", "dragon-dice/models/species", "dragon-dice/models/unit"], function (_exports, _dragonkin, _dragons, _species, _unit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.units = _exports.default = void 0;

  var _default = Ember.Service.extend({
    spells: Ember.inject.service(),

    getAllSpecies() {
      return allSpecies.map(x => _species.default.create(x));
    },

    getSpecies(id) {
      let species;

      if (id === 'dragonkin') {
        species = _dragonkin.default;
      } else if (id === 'dragons') {
        species = _dragons.default;
      } else {
        species = allSpecies.findBy('id', id);
      }

      let u = id === 'dragonkin' || id === 'dragons' ? species.units : units.filterBy('species', id);
      species = _species.default.create(species);
      species.set('units', u.map(x => _unit.default.create(x, {
        species: id
      })));
      return species;
    },

    getUnit(id, extra) {
      let unit = units.findBy('id', id);

      if (!unit) {
        return;
      }

      return _unit.default.create(units.findBy('id', id), extra);
    },

    search(searchTerm) {
      if (!searchTerm || searchTerm.length < 3) {
        return [];
      }

      let lowerSearchTerm = searchTerm.toLowerCase();
      return units.filter(x => {
        return x.name.toLowerCase().includes(lowerSearchTerm);
      });
    }

  });
  /*
  Coral Elves (blue and green) are peaceable sea-dwelling elves who travel in flying "coral ships".
  Dwarves (red and gold) are stalwart warriors with horned helmets, two-headed axes and shields.
  Goblins (gold and black) are disgusting, ravenous creatures who ride wolves or leopards and cast curses.
  Lava Elves (red and black) are fierce death-mages who wield pistols and rapiers.
  Amazons (ivory with purple icons), the game's only humans, cast magic based on the terrain they're stationed at. (Per Chuck Pint, President of SFR, Amazons are WHITE units, NOT IVORY) (Per the rulebook, "Amazons are ivory, which signifies that they are not inherently any specific aspect...")
  Firewalkers (blue and red) are warriors from the sun who flew to Esfah to join its battles for sport.
  Undead (black and ivory with green icons), the reanimated dead, can use only death magic.
  Ferals (gold and blue) are various species of humanoid beasts or birds who fight with stone knives.
  Swamp Stalkers (green and black) are hideous snake-people who mutate other species into themselves.
  Frostwings (black and blue) are furry flying creatures with an affinity for ice. (As the last TSR-published "kicker" pack, Frostwings were underprinted and have become atypically rare.)
  Scalders (red and green), the only "kicker" produced by Wizards of the Coast before the game's production ceased, are twisted, malicious faeries and can harm those who attack them.
  Treefolk (gold and green) were printed by SFR after they acquired the abandoned licence. As they use the last unique combination of the game's five colors, additional multi-colour species are unlikely.
  Eldarim are the original species of Esfah, and are represented by single colour promo dice called Champions, worth four health each. In 2009, SFR released a new expansion called "Acolytes of Eldarim," single colour units of one to three health who have given up their species heritage to learn from the Eldarim Champions. This "kicker" includes a new class of unit, the Shield Bearer.
  */


  _exports.default = _default;
  const allSpecies = [{
    id: 'coral-elves',
    name: 'Coral Elves',
    elements: ['water', 'air'],
    description: 'Coral Elves are the first species of Dragon Dice™. Made of the elements of Air and Water, Coral elves are a powerful army from the coastland.',
    abilities: [{
      title: 'Coastal Dodge',
      description: 'When at a terrain that contains green (water), Coral Elves may count maneuver results as if they were save results.'
    }, {
      title: 'Defensive Volley',
      description: 'When at a terrain that contains blue (air), Coral Elves units may counter-attack against a missile action. Follow the same process used for a regular melee counter-attack, using missile results instead of melee results.'
    }]
  }, {
    id: 'dwarves',
    name: 'Dwarves',
    elements: ['earth', 'fire'],
    description: 'Dwarves are the one of the first five species of Dragon Dice™. Dwarves are a resilient combination of Earth and Fire.',
    abilities: [{
      title: 'Mountain Mastery',
      description: 'When at a terrain that contains yellow (earth), Dwarves may count melee results as if they were maneuver results.'
    }, {
      title: 'Dwarven Might',
      description: 'When at a terrain that contains red (fire), Dwarves may count save results as if they were melee results when rolling for a counter-attack.'
    }]
  }, {
    id: 'goblins',
    name: 'Goblins',
    elements: ['earth', 'death'],
    description: 'Goblins are one of the first 5 species of Dragon Dice™. Bred for war and discord, Goblins are a dangerous combination of Earth and Death.',
    abilities: [{
      title: 'Swamp Mastery',
      description: 'When at a terrain that contains yellow (earth), Goblins may count melee results as if they were maneuver results.'
    }, {
      title: 'Foul Stench',
      description: 'When an army containing Goblins takes a melee action, the opposing player must select a number of their units after they have resolved their save roll. The selected units cannot perform a counter-attack during this melee action. The number of units that must be selected in this way is equal to the number of Goblin units in the Goblin player’s DUA, up to a maximum of three †.'
    }]
  }, {
    id: 'lava-elves',
    name: 'Lava Elves',
    elements: ['fire', 'death'],
    description: 'Lava Elves are the one of the first five species of Dragon Dice™. A deadly mixture of Fire and Death, Lava elves seek to destroy.',
    abilities: [{
      title: 'Volcanic Adaptation',
      description: 'When at a terrain that contains red (fire), Lava Elves may count maneuver results as if they were save results.'
    }, {
      title: 'Cursed Bullets',
      description: 'When targeting an army at the same terrain with a missile attack, Lava Elves missile results inflict damage that may only be reduced by save results generated by spells. The number of missile results that may be effected in this way is equal to the number of Lava Elves units in the Lava Elves player’s DUA, up to a maximum of three †.'
    }]
  }, {
    id: 'amazons',
    name: 'Amazons',
    elements: ['ivory'],
    description: 'Amazons are one of the first 5 species of Dragon Dice™. As the only ivory species, Amazons attune to the magic of the land around them.',
    abilities: [{
      title: 'Javelin Charge',
      description: 'During a march, instead of taking the maneuver step, you may bury a minor terrain the marching army controls. Amazons in that army may then count maneuver results as if they were missile results during a missile action this turn.'
    }, {
      title: 'Kukri Charge',
      description: 'During a march, instead of taking the maneuver step, you may bury a minor terrain the marching army controls. Amazons in that army may then count maneuver results as if they were melee results during a melee action this turn.'
    }, {
      title: 'Terrain Harmony',
      description: 'Amazon units generate magic results matching the colors of the terrain where they are located. Amazon units in the Reserves Area generate Ivory magic, which may only be used to cast Elemental spells.'
    }]
  }, {
    id: 'firewalkers',
    name: 'Firewalkers',
    elements: ['air', 'fire'],
    description: 'Firewalkers are the one of the latter species of Dragon Dice™. Bursting from the sun, Firewalkers are a combination of Fire and Air.',
    abilities: [{
      title: 'Air Flight',
      description: 'During the Retreat Step of the Reserves Phase, Firewalker units may move from any terrain that contains blue (air) to any other terrain that contains blue (air) and where you have at least one Firewalker unit.'
    }, {
      title: 'Flaming Shields',
      description: 'When at a terrain that contains red (fire), Firewalkers may count save results as if they were melee results. Flaming Shields does not apply when making a counter-attack.'
    }]
  }, {
    id: 'undead',
    name: 'Undead',
    elements: ['death'],
    description: 'Undead are the one of the latter species of Dragon Dice™. Rising from the grave, Undead are a species of Death and Ivory.',
    abilities: [{
      title: 'Stepped Damage',
      description: 'When an Undead unit is killed you may instead exchange it with an Undead unit of lesser health from your DUA.'
    }, {
      title: 'Bone Magic',
      description: 'When an army containing Undead takes a magic action, each Undead unit that rolls at least one non-ID magic result may add one additional magic result. The number of magic results that may be added in this way is equal to the number of Undead units in the Undead player’s DUA, up to a maximum of four †.'
    }]
  }, {
    id: 'feral',
    name: 'Feral',
    elements: ['earth', 'air'],
    description: 'The Feral are the one of the latter species of Dragon Dice™. The Feral are the beasts of Esfah\'s wilderness, The Feral are a combination of Air and Earth.',
    abilities: [{
      title: 'Feralization',
      description: 'Immediately before taking your first march, after dragon attacks have been resolved, each of your armies containing at least one Feral unit at a terrain that contains yellow (earth) or blue (air) may do one of the following: Recruit one health-worth of Feral to this army, OR, promote one Feral unit in the army.'
    }, {
      title: 'Stampede',
      description: 'When at a terrain that contains both yellow (earth) and blue (air), Feral units may count maneuver results as if they were melee results during a counter-attack.'
    }]
  }, {
    id: 'swamp-stalkers',
    name: 'Swamp Stalkers',
    elements: ['water', 'death'],
    description: 'Swamp Stalkers are the one of the latter species of Dragon Dice™. Mutating within the swamps, Swamp Stalkers are a combination of Water and Death.',
    abilities: [{
      title: 'Born of the Swamp',
      description: 'When at a terrain that contains green (water), Swamp Stalkers may count maneuver results as if they were save results.'
    }, {
      title: 'Mutate',
      description: 'Immediately before taking your first march, after dragon attacks have been resolved, you may attempt to Mutate providing the following criteria are met: a) an opposing player must have at least one unit in their Reserves Area, b) you must have at least one army containing a Swamp Stalker at a terrain, and c) you must have at least one Swamp Stalker unit in your DUA (or a Deadlands minor terrain in play). Target units in an opponent’s Reserve Area to make a save roll. Units that do not generate a save result are killed. One of your armies at a terrain that contains at least one Swamp Stalker unit can then recruit or promote Swamp Stalker units up to the health-worth that were killed this way. The number of units that may be targeted in this way is equal to the number of Swamp Stalker units in the Swamp Stalker player’s DUA, up to a maximum of one †.'
    }]
  }, {
    id: 'frostwings',
    name: 'Frostwings',
    elements: ['air', 'death'],
    description: 'Frostwings are one of the latter species of Dragon Dice™. Frozen terrors from the sky, Frostwings are a combination of Air and Death.',
    abilities: [{
      title: 'Winter’s Fortitude',
      description: 'Immediately before taking your first march, after dragon attacks have been resolved, if you have at least one Frostwing unit at a terrain that contains blue (air), you may move one Frostwing unit of your choice from your BUA to your DUA.'
    }, {
      title: 'Magic Negation',
      description: 'When an opponent takes a magic action at a terrain containing Frostwings, the Frostwing units may make a Magic Negation roll. Roll the Frostwing units before the opponent totals their magic results. Subtract the magic results generated by the Frostwing units from the opponent’s results. The number of magic results that may be subtracted is equal to the number of Frostwing units in the Frostwing player’s DUA, up to a maximum of five †.'
    }]
  }, {
    id: 'scalders',
    name: 'Scalders',
    elements: ['water', 'fire'],
    description: 'Scalders are the one of the latter species of Dragon Dice™. Burning and searing with every touch, Scalders are a scorching species of Fire and Water.',
    abilities: [{
      title: 'Scorching Touch',
      description: 'When at a terrain that contains red (fire), Scalders making a save roll against a melee action inflict one point of damage on the attacking army for each save result rolled. Only save results generated by spells may reduce this damage. Scorching Touch does not apply when saving against a counter-attack.'
    }, {
      title: 'Intangibility',
      description: 'When at a terrain that contains green (water), Scalders may count maneuver results as if they were save results against missile damage.'
    }]
  }, {
    id: 'treefolk',
    name: 'Treefolk',
    elements: ['water', 'earth'],
    description: 'Treefolk are the one of the latter species of Dragon Dice™. Sentient trees from the forests of Esfah, Treefolk are a combination of Water and Earth.',
    abilities: [{
      title: 'Rapid Growth',
      description: 'When at a terrain that contains yellow (earth), Treefolk units that do not roll an SAI result may be re-rolled once when making a counter-maneuver. The previous results are ignored. Any units you wish to re-roll in this way must be selected and re-rolled together.'
    }, {
      title: 'Replanting',
      description: 'When at a terrain that contains green (water), Treefolk units that are killed should be rolled before being moved to the DUA. Any units rolls an ID icon are instead moved to your Reserve Area.'
    }]
  }, {
    id: 'dragonkin',
    name: 'Dragonkin'
  }, {
    id: 'dragons',
    name: 'Dragons'
  }];
  const units = [{
    "id": "fighter",
    "name": "Fighter",
    "species": "coral-elves",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "trooper",
    "name": "Trooper",
    "species": "coral-elves",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "protector",
    "name": "Protector",
    "species": "coral-elves",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "guard",
    "name": "Guard",
    "species": "coral-elves",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "courier",
    "name": "Courier",
    "species": "coral-elves",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "herald",
    "name": "Herald",
    "species": "coral-elves",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }]
  }, {
    "id": "bowman",
    "name": "Bowman",
    "species": "coral-elves",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "archer",
    "name": "Archer",
    "species": "coral-elves",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "sharpshooter",
    "name": "Sharpshooter",
    "species": "coral-elves",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }]
  }, {
    "id": "horseman",
    "name": "Horseman",
    "species": "coral-elves",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "knight",
    "name": "Knight",
    "species": "coral-elves",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "eagleKnight",
    "name": "Eagle Knight",
    "species": "coral-elves",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 3
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "evoker",
    "name": "Evoker",
    "species": "coral-elves",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "conjurer",
    "name": "Conjurer",
    "species": "coral-elves",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "enchanter",
    "name": "Enchanter",
    "species": "coral-elves",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }]
  }, {
    "id": "coralGiant",
    "name": "Coral Giant",
    "species": "coral-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "gryphon",
    "name": "Gryphon",
    "species": "coral-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "ferry",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "ferry",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "leviathan",
    "name": "Leviathan",
    "species": "coral-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "swallow",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "tail",
      "value": 2
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "wave",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "hypnoticGlare",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "tail",
      "value": 2
    }]
  }, {
    "id": "spriteSwarm",
    "name": "Sprite Swarm",
    "species": "coral-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }]
  }, {
    "id": "tako",
    "name": "Tako",
    "species": "coral-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "entangle",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "entangle",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "footman",
    "name": "Footman",
    "species": "dwarves",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "sergeant",
    "name": "Sergeant",
    "species": "dwarves",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "warlord",
    "name": "Warlord",
    "species": "dwarves",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "sentry",
    "name": "Sentry",
    "species": "dwarves",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "patroller",
    "name": "Patroller",
    "species": "dwarves",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "skirmisher",
    "name": "Skirmisher",
    "species": "dwarves",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }]
  }, {
    "id": "crossbowman",
    "name": "Crossbowman",
    "species": "dwarves",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "marksman",
    "name": "Marksman",
    "species": "dwarves",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "crack-shot",
    "name": "Crack-Shot",
    "species": "dwarves",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "ponyRider",
    "name": "Pony Rider",
    "species": "dwarves",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "lizardRider",
    "name": "Lizard Rider",
    "species": "dwarves",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "mammothRider",
    "name": "Mammoth Rider",
    "species": "dwarves",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 3
    }, {
      "type": "sai",
      "action": "trample",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }]
  }, {
    "id": "theurgist",
    "name": "Theurgist",
    "species": "dwarves",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "thaumaturgist",
    "name": "Thaumaturgist",
    "species": "dwarves",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "wizard",
    "name": "Wizard",
    "species": "dwarves",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }]
  }, {
    "id": "androsphinx",
    "name": "Androsphinx",
    "species": "dwarves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "roar",
      "variant": 2,
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "roar",
      "variant": 2,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "behemoth",
    "name": "Behemoth",
    "species": "dwarves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "stomp",
      "value": 4
    }, {
      "type": "sai",
      "action": "bash",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "roar",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "sai",
      "action": "charge",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "gargoyle",
    "name": "Gargoyle",
    "species": "dwarves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 2,
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "roc",
    "name": "Roc",
    "species": "dwarves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "sai",
      "action": "seize",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "seize",
      "value": 4
    }]
  }, {
    "id": "umberHulk",
    "name": "Umber Hulk",
    "species": "dwarves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "confuse",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "confuse",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "thug",
    "name": "Thug",
    "species": "goblins",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "cutthroat",
    "name": "Cutthroat",
    "species": "goblins",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "marauder",
    "name": "Marauder",
    "species": "goblins",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "mugger",
    "name": "Mugger",
    "species": "goblins",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "ambusher",
    "name": "Ambusher",
    "species": "goblins",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "filcher",
    "name": "Filcher",
    "species": "goblins",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }]
  }, {
    "id": "pelter",
    "name": "Pelter",
    "species": "goblins",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "slingman",
    "name": "Slingman",
    "species": "goblins",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "deadeye",
    "name": "Deadeye",
    "species": "goblins",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }]
  }, {
    "id": "wardogRider",
    "name": "Wardog Rider",
    "species": "goblins",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "wolfRider",
    "name": "Wolf Rider",
    "species": "goblins",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "leopardRider",
    "name": "Leopard Rider",
    "species": "goblins",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "sai",
      "action": "rend",
      "value": 3
    }, {
      "type": "sai",
      "action": "rend",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "trickster",
    "name": "Trickster",
    "species": "goblins",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "hedgeWizard",
    "name": "Hedge Wizard",
    "species": "goblins",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "deathMage",
    "name": "Death Mage",
    "species": "goblins",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }]
  }, {
    "id": "cannibal",
    "name": "Cannibal",
    "species": "goblins",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "swallow",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "stun",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "net",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "sai",
      "action": "sleep",
      "value": 4
    }]
  }, {
    "id": "deathNaga",
    "name": "Death Naga",
    "species": "goblins",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "poison",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "poison",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "harpies",
    "name": "Harpies",
    "species": "goblins",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "screech",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "screech",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }]
  }, {
    "id": "shambler",
    "name": "Shambler",
    "species": "goblins",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "smother",
      "value": 4
    }, {
      "type": "sai",
      "action": "smother",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "troll",
    "name": "Troll",
    "species": "goblins",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "regenerate",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "regenerate",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "bladesman",
    "name": "Bladesman",
    "species": "lava-elves",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "duelist",
    "name": "Duelist",
    "species": "lava-elves",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "conqueror",
    "name": "Conqueror",
    "species": "lava-elves",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 5
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "scout",
    "name": "Scout",
    "species": "lava-elves",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "spy",
    "name": "Spy",
    "species": "lava-elves",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "infiltrator",
    "name": "Infiltrator",
    "species": "lava-elves",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 5
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 5
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }]
  }, {
    "id": "fusilier",
    "name": "Fusilier",
    "species": "lava-elves",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "dead-shot",
    "name": "Dead-Shot",
    "species": "lava-elves",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "missile",
      "value": 4
    }]
  }, {
    "id": "assassin",
    "name": "Assassin",
    "species": "lava-elves",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 5
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "spiderRider",
    "name": "Spider Rider",
    "species": "lava-elves",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "scorpionKnight",
    "name": "Scorpion Knight",
    "species": "lava-elves",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "wyvernRider",
    "name": "Wyvern Rider",
    "species": "lava-elves",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 5
    }, {
      "type": "sai",
      "action": "fly",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 4
    }]
  }, {
    "id": "adept",
    "name": "Adept",
    "species": "lava-elves",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "warlock",
    "name": "Warlock",
    "species": "lava-elves",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "magic",
      "value": 4
    }]
  }, {
    "id": "necromancer",
    "name": "Necromancer",
    "species": "lava-elves",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 5
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }]
  }, {
    "id": "beholder",
    "name": "Beholder",
    "species": "lava-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "flame",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "charm",
      "value": 4
    }, {
      "type": "sai",
      "action": "stone",
      "value": 4
    }, {
      "type": "sai",
      "action": "confuse",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "illusion",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "drider",
    "name": "Drider",
    "species": "lava-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "web",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "web",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "hellHound",
    "name": "Hell Hound",
    "species": "lava-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "flame",
      "value": 1
    }, {
      "type": "sai",
      "action": "flame",
      "value": 1
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "lurkerInTheDeep",
    "name": "Lurker in the Deep",
    "species": "lava-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "volley",
      "value": 4
    }, {
      "type": "sai",
      "action": "cloak",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "rakshasa",
    "name": "Rakshasa",
    "species": "lava-elves",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "illusion",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "illusion",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "soldier",
    "name": "Soldier",
    "species": "amazons",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "warrior",
    "name": "Warrior",
    "species": "amazons",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "warChief",
    "name": "War Chief",
    "species": "amazons",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "runner",
    "name": "Runner",
    "species": "amazons",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "envoy",
    "name": "Envoy",
    "species": "amazons",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "harbinger",
    "name": "Harbinger",
    "species": "amazons",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }]
  }, {
    "id": "darter",
    "name": "Darter",
    "species": "amazons",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }]
  }, {
    "id": "javelineer",
    "name": "Javelineer",
    "species": "amazons",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "spearer",
    "name": "Spearer",
    "species": "amazons",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }]
  }, {
    "id": "charioteer",
    "name": "Charioteer",
    "species": "amazons",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "battleRider",
    "name": "Battle Rider",
    "species": "amazons",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "warDriver",
    "name": "War Driver",
    "species": "amazons",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "sai",
      "action": "trample",
      "value": 3
    }, {
      "type": "sai",
      "action": "trample",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "seer",
    "name": "Seer",
    "species": "amazons",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "visionary",
    "name": "Visionary",
    "species": "amazons",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "oracle",
    "name": "Oracle",
    "species": "amazons",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 5
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 3
    }]
  }, {
    "id": "centaur",
    "name": "Centaur",
    "species": "amazons",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "sai",
      "action": "kick",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "kick",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }]
  }, {
    "id": "chimera",
    "name": "Chimera",
    "species": "amazons",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "flame",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "hydra",
    "name": "Hydra",
    "species": "amazons",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "medusa",
    "name": "Medusa",
    "species": "amazons",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "stone",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "stone",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "nightmare",
    "name": "Nightmare",
    "species": "amazons",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "firewalking",
      "value": 4
    }, {
      "type": "sai",
      "action": "firebreath",
      "value": 4
    }, {
      "type": "sai",
      action: "fly",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "variant": 2,
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }]
  }, {
    "id": "guardian",
    "name": "Guardian",
    "species": "firewalkers",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "watcher",
    "name": "Watcher",
    "species": "firewalkers",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "sentinel",
    "name": "Sentinel",
    "species": "firewalkers",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "explorer",
    "name": "Explorer",
    "species": "firewalkers",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "adventurer",
    "name": "Adventurer",
    "species": "firewalkers",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "expeditioner",
    "name": "Expeditioner",
    "species": "firewalkers",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "firestarter",
    "name": "Firestarter",
    "species": "firewalkers",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "firemaster",
    "name": "Firemaster",
    "species": "firewalkers",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "firestormer",
    "name": "Firestormer",
    "species": "firewalkers",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "shadowchaser",
    "name": "Shadowchaser",
    "species": "firewalkers",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "nightsbane",
    "name": "Nightsbane",
    "species": "firewalkers",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "daybringer",
    "name": "Daybringer",
    "species": "firewalkers",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "sunburst",
    "name": "Sunburst",
    "species": "firewalkers",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "sunflare",
    "name": "Sunflare",
    "species": "firewalkers",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "ashbringer",
    "name": "Ashbringer",
    "species": "firewalkers",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "fireshadow",
    "name": "Fireshadow",
    "species": "firewalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "sai",
      "action": "createFireminions",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "cantrip",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "createFireminions",
      "value": 4
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }]
  }, {
    "id": "genie",
    "name": "Genie",
    "species": "firewalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "galeforce",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "cantrip",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "firecloud",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "firewalking",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }]
  }, {
    "id": "gorgon",
    "name": "Gorgon",
    "species": "firewalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "flame",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "flame",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "phoenix",
    "name": "Phoenix",
    "species": "firewalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "sai",
      "action": "riseFromTheAshes",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "sai",
      "action": "seize",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "riseFromTheAshes",
      "value": 4
    }, {
      "type": "sai",
      "action": "seize",
      "value": 4
    }]
  }, {
    "id": "salamander",
    "name": "Salamander",
    "species": "firewalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "cantrip",
      "variant": 2,
      "value": 4
    }]
  }, {
    "id": "zombie",
    "name": "Zombie",
    "species": "undead",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "wight",
    "name": "Wight",
    "species": "undead",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "mummy",
    "name": "Mummy",
    "species": "undead",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "wither",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "skeleton",
    "name": "Skeleton",
    "species": "undead",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "revenant",
    "name": "Revenant",
    "species": "undead",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "deathKnight",
    "name": "Death Knight",
    "species": "undead",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "scare",
      "value": 3
    }]
  }, {
    "id": "spectre",
    "name": "Spectre",
    "species": "undead",
    "type": "skirmisher",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "wraith",
    "name": "Wraith",
    "species": "undead",
    "type": "skirmisher",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "ghost",
    "name": "Ghost",
    "species": "undead",
    "type": "skirmisher",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "vanish",
      "value": 3
    }]
  }, {
    "id": "ghoul",
    "name": "Ghoul",
    "species": "undead",
    "type": "lightmagic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }]
  }, {
    "id": "ghast",
    "name": "Ghast",
    "species": "undead",
    "type": "lightmagic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "vampire",
    "name": "Vampire",
    "species": "undead",
    "type": "lightmagic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "convert",
      "value": 3
    }]
  }, {
    "id": "apparition",
    "name": "Apparition",
    "species": "undead",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "heucuva",
    "name": "Heucuva",
    "species": "undead",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "lich",
    "name": "Lich",
    "species": "undead",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }]
  }, {
    "id": "carrionCrawler",
    "name": "Carrion Crawler",
    "species": "undead",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "stun",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "stun",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "dracolich",
    "name": "Dracolich",
    "species": "undead",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "fenhound",
    "name": "Fenhound",
    "species": "undead",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }]
  }, {
    "id": "minorDeath",
    "name": "Minor Death",
    "species": "undead",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "plague",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "slay",
      "value": 4
    }, {
      "type": "sai",
      "action": "slay",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "plague",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "skeletalSteed",
    "name": "Skeletal Steed",
    "species": "undead",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "tiger-folk",
    "name": "Tiger-folk",
    "species": "feral",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "leopard-folk",
    "name": "Leopard-folk",
    "species": "feral",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "lynx-folk",
    "name": "Lynx-folk",
    "species": "feral",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "wolf-folk",
    "name": "Wolf-folk",
    "species": "feral",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "rend",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 4
    }]
  }, {
    "id": "fox-folk",
    "name": "Fox-folk",
    "species": "feral",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "hound-folk",
    "name": "Hound-folk",
    "species": "feral",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 1
    }]
  }, {
    "id": "vulture-folk",
    "name": "Vulture-folk",
    "species": "feral",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "hawk-folk",
    "name": "Hawk-folk",
    "species": "feral",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "falcon-folk",
    "name": "Falcon-folk",
    "species": "feral",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "buffalo-folk",
    "name": "Buffalo-folk",
    "species": "feral",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "horse-folk",
    "name": "Horse-folk",
    "species": "feral",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }]
  }, {
    "id": "antelope-folk",
    "name": "Antelope-folk",
    "species": "feral",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "wolverine-folk",
    "name": "Wolverine-folk",
    "species": "feral",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "badger-folk",
    "name": "Badger-folk",
    "species": "feral",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "weasel-folk",
    "name": "Weasel-folk",
    "species": "feral",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "bear-folk",
    "name": "Bear-folk",
    "species": "feral",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 4
    }, {
      "type": "sai",
      "action": "hug",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }]
  }, {
    "id": "elephant-folk",
    "name": "Elephant-folk",
    "species": "feral",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "trumpet",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "trumpet",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "lion-folk",
    "name": "Lion-folk",
    "species": "feral",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "roar",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "sai",
      "action": "roar",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "owl-folk",
    "name": "Owl-folk",
    "species": "feral",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "screech",
      "value": 4
    }, {
      "type": "sai",
      "action": "seize",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }]
  }, {
    "id": "rhino-folk",
    "name": "Rhino-folk",
    "species": "feral",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "gore",
      "value": 4
    }, {
      "type": "sai",
      "action": "gore",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }]
  }, {
    "id": "annihilator",
    "name": "Annihilator",
    "species": "swamp-stalkers",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 5
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "ravager",
    "name": "Ravager",
    "species": "swamp-stalkers",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "warmonger",
    "name": "Warmonger",
    "species": "swamp-stalkers",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "invader",
    "name": "Invader",
    "species": "swamp-stalkers",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "sai",
      "action": "poison",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "raider",
    "name": "Raider",
    "species": "swamp-stalkers",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "striker",
    "name": "Striker",
    "species": "swamp-stalkers",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "deluger",
    "name": "Deluger",
    "species": "swamp-stalkers",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 5
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }, {
      "type": "save",
      "value": 3
    }]
  }, {
    "id": "stormer",
    "name": "Stormer",
    "species": "swamp-stalkers",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "sprayer",
    "name": "Sprayer",
    "species": "swamp-stalkers",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }]
  }, {
    "id": "waveRider",
    "name": "Wave Rider",
    "species": "swamp-stalkers",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 5
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "sai",
      "action": "coil",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "marshSwimmer",
    "name": "Marsh Swimmer",
    "species": "swamp-stalkers",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "bogRunner",
    "name": "Bog Runner",
    "species": "swamp-stalkers",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "swampWizard",
    "name": "Swamp Wizard",
    "species": "swamp-stalkers",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "magic",
      "value": 5
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "marshMage",
    "name": "Marsh Mage",
    "species": "swamp-stalkers",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "bogAdept",
    "name": "Bog Adept",
    "species": "swamp-stalkers",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }]
  }, {
    "id": "crocosaur",
    "name": "Crocosaur",
    "species": "swamp-stalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "sai",
      "action": "tail",
      "value": 2
    }, {
      "type": "sai",
      "action": "tail",
      "value": 2
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "mudmen",
    "name": "Mudmen",
    "species": "swamp-stalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smother",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smother",
      "value": 4
    }]
  }, {
    "id": "ormyrr",
    "name": "Ormyrr",
    "species": "swamp-stalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "coil",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "swampBeast",
    "name": "Swamp Beast",
    "species": "swamp-stalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "sai",
      "action": "poison",
      "variant": 2,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "wave",
      "value": 4
    }, {
      "type": "sai",
      "action": "poison",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "wave",
      "value": 4
    }]
  }, {
    "id": "swampGiant",
    "name": "Swamp Giant",
    "species": "swamp-stalkers",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "poison",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "value": 4
    }]
  }, {
    "id": "devastator",
    "name": "Devastator",
    "species": "frostwings",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "sai",
      "action": "fly",
      "value": 3
    }, {
      "type": "missile",
      "value": 4
    }]
  }, {
    "id": "dispatcher",
    "name": "Dispatcher",
    "species": "frostwings",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "sai",
      "action": "fly",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "destroyer",
    "name": "Destroyer",
    "species": "frostwings",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "sai",
      "action": "fly",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "assailer",
    "name": "Assailer",
    "species": "frostwings",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "volley",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "assaulter",
    "name": "Assaulter",
    "species": "frostwings",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "sai",
      "action": "fly",
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "attacker",
    "name": "Attacker",
    "species": "frostwings",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "sai",
      "action": "fly",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "vindicator",
    "name": "Vindicator",
    "species": "frostwings",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "defender",
    "name": "Defender",
    "species": "frostwings",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "fly",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }]
  }, {
    "id": "advocate",
    "name": "Advocate",
    "species": "frostwings",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "sai",
      "action": "fly",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }]
  }, {
    "id": "bearMaster",
    "name": "Bear Master",
    "species": "frostwings",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "wolfMaster",
    "name": "Wolf Master",
    "species": "frostwings",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }]
  }, {
    "id": "houndMaster",
    "name": "Hound Master",
    "species": "frostwings",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }]
  }, {
    "id": "magi",
    "name": "Magi",
    "species": "frostwings",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }]
  }, {
    "id": "magus",
    "name": "Magus",
    "species": "frostwings",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "sai",
      "action": "fly",
      "value": 3
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "apprentice",
    "name": "Apprentice",
    "species": "frostwings",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "sai",
      "action": "fly",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }]
  }, {
    "id": "cryohydra",
    "name": "Cryohydra",
    "species": "frostwings",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "frostBreath",
      "value": 4
    }]
  }, {
    "id": "frostOgre",
    "name": "Frost Ogre",
    "species": "frostwings",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "remorhaz",
    "name": "Remorhaz",
    "species": "frostwings",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "swallow",
      "value": 1
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "swallow",
      "value": 1
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "wolfPack",
    "name": "Wolf Pack",
    "species": "frostwings",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "howl",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "howl",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }]
  }, {
    "id": "yeti",
    "name": "Yeti",
    "species": "frostwings",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }]
  }, {
    "id": "searer",
    "name": "Searer",
    "species": "scalders",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 6
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "scorcher",
    "name": "Scorcher",
    "species": "scalders",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "singeman",
    "name": "Singeman",
    "species": "scalders",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 1
    }]
  }, {
    "id": "charkin",
    "name": "Charkin",
    "species": "scalders",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 5
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "igniter",
    "name": "Igniter",
    "species": "scalders",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }]
  }, {
    "id": "kindler",
    "name": "Kindler",
    "species": "scalders",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }]
  }, {
    "id": "blazer",
    "name": "Blazer",
    "species": "scalders",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "flamingArrow",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "missile",
      "value": 6
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "burner",
    "name": "Burner",
    "species": "scalders",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "missile",
      "value": 3
    }]
  }, {
    "id": "glower",
    "name": "Glower",
    "species": "scalders",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "missile",
      "value": 1
    }]
  }, {
    "id": "dragonneKnight",
    "name": "Dragonne Knight",
    "species": "scalders",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 6
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }]
  }, {
    "id": "dragonneRider",
    "name": "Dragonne Rider",
    "species": "scalders",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 3
    }]
  }, {
    "id": "dragonneTender",
    "name": "Dragonne Tender",
    "species": "scalders",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }]
  }, {
    "id": "inferno",
    "name": "Inferno",
    "species": "scalders",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 6
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "smolderer",
    "name": "Smolderer",
    "species": "scalders",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "magic",
      "value": 3
    }]
  }, {
    "id": "sparker",
    "name": "Sparker",
    "species": "scalders",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "magic",
      "value": 1
    }]
  }, {
    "id": "ettercap",
    "name": "Ettercap",
    "species": "scalders",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "bullseye",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }]
  }, {
    "id": "quickling",
    "name": "Quickling",
    "species": "scalders",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "poison",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }]
  }, {
    "id": "unseelieFaerie",
    "name": "Unseelie Faerie",
    "species": "scalders",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "webBirds",
    "name": "Web Birds",
    "species": "scalders",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "sai",
      "action": "web",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "sai",
      "action": "web",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }]
  }, {
    "id": "willO'Wisp",
    "name": "Will o' Wisp",
    "species": "scalders",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "sai",
      "action": "confuse",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "sai",
      "action": "fly",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }]
  }, {
    "id": "oakLord",
    "name": "Oak Lord",
    "species": "treefolk",
    "type": "heavy",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 3
    }, {
      "type": "sai",
      "action": "smite",
      "value": 3
    }, {
      "type": "melee",
      "value": 3
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "oak",
    "name": "Oak",
    "species": "treefolk",
    "type": "heavy",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "oakling",
    "name": "Oakling",
    "species": "treefolk",
    "type": "heavy",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "nobleWillow",
    "name": "Noble Willow",
    "species": "treefolk",
    "type": "light",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "wildGrowth",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "willow",
    "name": "Willow",
    "species": "treefolk",
    "type": "light",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }, {
      "type": "melee",
      "value": 3
    }]
  }, {
    "id": "willowling",
    "name": "Willowling",
    "species": "treefolk",
    "type": "light",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }, {
      "type": "melee",
      "value": 2
    }]
  }, {
    "id": "pinePrince",
    "name": "Pine Prince",
    "species": "treefolk",
    "type": "missile",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "sai",
      "action": "volley",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "save",
      "value": 2
    }]
  }, {
    "id": "pine",
    "name": "Pine",
    "species": "treefolk",
    "type": "missile",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "missile",
      "value": 3
    }, {
      "type": "missile",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }]
  }, {
    "id": "pineling",
    "name": "Pineling",
    "species": "treefolk",
    "type": "missile",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "missile",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 1
    }, {
      "type": "missile",
      "value": 2
    }]
  }, {
    "id": "ladyNereid",
    "name": "Lady Nereid",
    "species": "treefolk",
    "type": "cavalry",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    "id": "naiad",
    "name": "Naiad",
    "species": "treefolk",
    "type": "cavalry",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 3
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }]
  }, {
    "id": "nymph",
    "name": "Nymph",
    "species": "treefolk",
    "type": "cavalry",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 1
    }, {
      "type": "maneuver",
      "variant": 1,
      "value": 2
    }]
  }, {
    "id": "eldarDryad",
    "name": "Eldar Dryad",
    "species": "treefolk",
    "type": "magic",
    "rarity": "rare",
    "health": 3,
    "faces": [{
      "type": "id",
      "value": 3
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "sai",
      "action": "cantrip",
      "value": 4
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "save",
      "value": 3
    }]
  }, {
    "id": "dryad",
    "name": "Dryad",
    "species": "treefolk",
    "type": "magic",
    "rarity": "uncommon",
    "health": 2,
    "faces": [{
      "type": "id",
      "value": 2
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "melee",
      "value": 2
    }, {
      "type": "magic",
      "value": 3
    }, {
      "type": "magic",
      "value": 2
    }, {
      "type": "save",
      "value": 3
    }]
  }, {
    "id": "hamadryad",
    "name": "Hamadryad",
    "species": "treefolk",
    "type": "magic",
    "rarity": "common",
    "health": 1,
    "faces": [{
      "type": "id",
      "value": 1
    }, {
      "type": "save",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "magic",
      "value": 1
    }, {
      "type": "melee",
      "value": 1
    }, {
      "type": "magic",
      "value": 2
    }]
  }, {
    "id": "darktree",
    "name": "Darktree",
    "species": "treefolk",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "smother",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "surprise",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "smother",
      "value": 4
    }]
  }, {
    "id": "redwood",
    "name": "Redwood",
    "species": "treefolk",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "variant": 2,
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "variant": 2,
      "value": 4
    }]
  }, {
    "id": "satyr",
    "name": "Satyr",
    "species": "treefolk",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "sleep",
      "value": 4
    }, {
      "type": "sai",
      "action": "volley",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 3,
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }, {
      "type": "sai",
      "action": "volley",
      "value": 4
    }, {
      "type": "sai",
      "action": "confuse",
      "value": 4
    }, {
      "type": "missile",
      "value": 4
    }, {
      "type": "sai",
      "action": "sleep",
      "value": 4
    }, {
      "type": "magic",
      "value": 4
    }]
  }, {
    "id": "strangleVine",
    "name": "Strangle Vine",
    "species": "treefolk",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "melee",
      "value": 4
    }, {
      "type": "sai",
      "action": "doubleStrike",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }, {
      "type": "sai",
      "action": "choke",
      "value": 4
    }, {
      "type": "sai",
      "action": "choke",
      "value": 4
    }, {
      "type": "sai",
      "action": "smother",
      "value": 4
    }, {
      "type": "sai",
      "action": "wildGrowth",
      "value": 4
    }, {
      "type": "sai",
      "action": "rend",
      "value": 4
    }, {
      "type": "maneuver",
      "variant": 2,
      "value": 4
    }]
  }, {
    "id": "unicorn",
    "name": "Unicorn",
    "species": "treefolk",
    "type": "monster",
    "rarity": "monster",
    "health": 4,
    "faces": [{
      "type": "id",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "variant": 1,
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }, {
      "type": "sai",
      "action": "teleport",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "sai",
      "action": "dispelMagic",
      "value": 4
    }, {
      "type": "sai",
      "action": "counter",
      "value": 4
    }, {
      "type": "sai",
      "action": "trample",
      "variant": 1,
      "value": 4
    }, {
      "type": "sai",
      "action": "teleport",
      "value": 4
    }, {
      "type": "save",
      "value": 4
    }]
  }, {
    id: 'drake',
    name: 'Drake',
    type: 'dragon',
    species: 'dragons',
    faces: [{
      type: 'id'
    }, {
      type: 'sai',
      action: 'dragon-breath',
      label: 'Breath',
      variant: 'd'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'd-1'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'd-2'
    }, {
      type: 'sai',
      action: 'dragon-belly',
      label: 'Belly',
      variant: 'd-1'
    }, {
      type: 'sai',
      action: 'dragon-belly',
      label: 'Belly',
      variant: 'd-2'
    }, {
      type: 'sai',
      action: 'dragon-wing',
      label: 'Wing',
      variant: 'd-1'
    }, {
      type: 'sai',
      action: 'dragon-wing',
      label: 'Wing',
      variant: 'd-2'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'd-3'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'd-4'
    }, {
      type: 'sai',
      action: 'dragon-tail',
      label: 'Tail',
      variant: 'd-1'
    }, {
      type: 'sai',
      action: 'dragon-tail',
      label: 'Tail',
      variant: 'd-2'
    }]
  }, {
    id: 'wyrm',
    name: 'Wyrm',
    type: 'dragon',
    species: 'dragons',
    faces: [{
      type: 'id'
    }, {
      type: 'sai',
      action: 'dragon-breath',
      label: 'Breath',
      variant: 'w'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'w-1'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'w-2'
    }, {
      type: 'sai',
      action: 'dragon-belly',
      label: 'Belly',
      variant: 'w-1'
    }, {
      type: 'sai',
      action: 'dragon-belly',
      label: 'Belly',
      variant: 'w-2'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'w-3'
    }, {
      type: 'sai',
      action: 'dragon-claw',
      label: 'Claw',
      variant: 'w-4'
    }, {
      type: 'sai',
      action: 'dragon-tail',
      label: 'Tail',
      variant: 'w-1'
    }, {
      type: 'sai',
      action: 'dragon-tail',
      label: 'Tail',
      variant: 'w-2'
    }, {
      type: 'sai',
      action: 'dragon-tail',
      label: 'Tail',
      variant: 'w-3'
    }, {
      type: 'sai',
      action: 'dragon-treasure',
      label: 'Treasure',
      variant: 'w'
    }]
  }];
  _exports.units = units;
});