define("dragon-dice/transitions/exit-right", ["exports", "dragon-dice/transitions/move-over-half"], function (_exports, _moveOverHalf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(opts) {
    return _moveOverHalf.default.call(this, 'x', 1, opts);
  }
});