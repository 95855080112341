define("dragon-dice/pods/race/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This route exists to redirect users from `race/<species>` to `species/<species>`. If you're reading this from The
  // Future™, consider whether we still need to keep the redirect around.
  class _default extends Ember.Route {
    redirect(_, transition) {
      let [species, subroute] = transition.to.params[''].split('/');
      let newTarget = ['species', subroute].filter(Boolean).join('.');
      this.transitionTo(newTarget, {
        species_id: species
      });
    }

  }

  _exports.default = _default;
});