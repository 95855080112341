define("dragon-dice/pods/species-dragons/units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xW8BdVhw",
    "block": "{\"symbols\":[\"details\",\"currentDetails\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"section-left\"],[8],[0,\"\\n  \"],[7,\"header\",true],[8],[0,\"\\n    \"],[1,[28,\"x-grouper\",null,[[\"groupBy\",\"groupByOptions\"],[[24,[\"groupBy\"]],[24,[\"groupByOptions\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"species-breakdown\",null,[[\"units\",\"groupBy\",\"groupByOptions\",\"shouldOverflow\",\"overflowContainer\"],[[24,[\"model\",\"units\"]],[24,[\"groupBy\"]],[24,[\"groupByOptions\"]],[28,\"media\",[\"twoColumn\"],null],\"overflow\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"section-right\"],[8],[0,\"\\n\"],[4,\"from-elsewhere\",null,[[\"name\",\"tagName\"],[\"overflow\",\"ul\"]],{\"statements\":[[4,\"liquid-bind\",[[23,1,[]]],[[\"use\",\"enableGrowth\"],[\"crossFade\",false]],{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[23,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species-dragons/units/template.hbs"
    }
  });

  _exports.default = _default;
});