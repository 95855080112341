define("dragon-dice/data/species/dragons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "id": "dragons",
    "name": "Dragons",
    "elements": [],
    "description": "Dragons can be summoned using magic to wage war against armies on the battlefield. They are tough adversaries and even the most battle-hardy army has been known to fall to a dragon.",
    "hasSpells": false,
    "abilities": [{
      "title": "Automatic Saves",
      "description": "All Dragons have armored skin, which grants them automatic save results equal to their health."
    }],
    "units": [{
      "id": "drake",
      "name": "Drake",
      "type": "dragon",
      "rarity": "dragon",
      "health": 5,
      "faces": [{
        "type": "id"
      }, {
        "type": "sai",
        "action": "dragon-breath",
        "variant": "d"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "d-1"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "d-2"
      }, {
        "type": "sai",
        "action": "dragon-belly",
        "variant": "d-1"
      }, {
        "type": "sai",
        "action": "dragon-belly",
        "variant": "d-2"
      }, {
        "type": "sai",
        "action": "dragon-wing",
        "variant": "d-1"
      }, {
        "type": "sai",
        "action": "dragon-wing",
        "variant": "d-2"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "d-3"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "d-4"
      }, {
        "type": "sai",
        "action": "dragon-tail",
        "variant": "d-1"
      }, {
        "type": "sai",
        "action": "dragon-tail",
        "variant": "d-2"
      }]
    }, {
      "id": "wyrm",
      "name": "Wyrm",
      "type": "dragon",
      "rarity": "dragon",
      "health": 5,
      "faces": [{
        "type": "id"
      }, {
        "type": "sai",
        "action": "dragon-breath",
        "variant": "w"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "w-1"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "w-2"
      }, {
        "type": "sai",
        "action": "dragon-belly",
        "variant": "w-1"
      }, {
        "type": "sai",
        "action": "dragon-belly",
        "variant": "w-2"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "w-3"
      }, {
        "type": "sai",
        "action": "dragon-claw",
        "variant": "w-4"
      }, {
        "type": "sai",
        "action": "dragon-tail",
        "variant": "w-1"
      }, {
        "type": "sai",
        "action": "dragon-tail",
        "variant": "w-2"
      }, {
        "type": "sai",
        "action": "dragon-tail",
        "variant": "w-3"
      }, {
        "type": "sai",
        "action": "dragon-treasure",
        "variant": "w"
      }]
    }]
  };
  _exports.default = _default;
});