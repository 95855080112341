define("dragon-dice/pods/components/unit-overview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['species'],
    species: Ember.computed('unit.species', function () {
      return `species--${this.unit.species}`;
    }),
    actions: {
      increaseQuantity() {
        this.get('unit').incrementProperty('qty');
        return false;
      },

      decreaseQuantity() {
        this.get('unit').decrementProperty('qty');
        return false;
      }

    }
  });

  _exports.default = _default;
});