define("dragon-dice/pods/components/face-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TBD9C27l",
    "block": "{\"symbols\":[\"&default\",\"@title\",\"@subtitle\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"figcaption\",true],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"title\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"subtitle\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-icon\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onDecQty\"]]],null]],[10,\"type\",\"button\"],[8],[1,[28,\"i\",[\"minus\"],null],false],[9],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-icon\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onIncQty\"]]],null]],[10,\"type\",\"button\"],[8],[1,[28,\"i\",[\"plus\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/face-card/template.hbs"
    }
  });

  _exports.default = _default;
});