define("dragon-dice/pods/species/units/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let _class = (_class2 = (_temp = class _class2 extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['groupBy']);

      _defineProperty(this, "groupByOptions", ['type', 'rarity']);

      _defineProperty(this, "groupBy", 'type');
    }

    async addToArmy(unitId, army) {
      await army.addUnit(unitId).save();
      this.set('showAddToArmy', null);
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "addToArmy", [Ember._action], Object.getOwnPropertyDescriptor(_class2.prototype, "addToArmy"), _class2.prototype)), _class2);

  _exports.default = _class;
});