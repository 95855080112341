define("dragon-dice/adapters/application", ["exports", "pouchdb", "ember-pouch"], function (_exports, _pouchdb, _emberPouch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _emberPouch.Adapter {
    constructor() {
      super(...arguments);
      let db = new _pouchdb.default('dragon-dice');
      this.set('db', db);
    }

  }

  _exports.default = _default;
});