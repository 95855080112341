define("dragon-dice/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  let duration = 200;

  function _default() {
    this.transition(this.fromRoute('index'), this.toRoute('species'), this.use('exit-left', {
      duration
    }), this.reverse('exit-right', {
      duration
    }));
    this.transition(this.fromRoute('index'), this.toRoute('species-dragons'), this.use('exit-left', {
      duration
    }), this.reverse('exit-right', {
      duration
    }));
    this.transition(this.fromRoute('index'), this.toRoute('spell-element'), this.use('exit-left', {
      duration
    }), this.reverse('exit-right', {
      duration
    }));
    this.transition(this.fromRoute('index'), this.toRoute('army'), this.use('exit-left', {
      duration
    }), this.reverse('exit-right', {
      duration
    })); // Dragons

    this.transition(this.fromRoute('species-dragons.units'), this.toRoute('species-dragons.sais'), this.use('toLeft', {
      duration
    }), this.reverse('toRight', {
      duration
    }));
    this.transition(this.fromRoute('species-dragons.units'), this.toRoute('species-dragons.breath'), this.use('toLeft', {
      duration
    }), this.reverse('toRight', {
      duration
    }));
    this.transition(this.fromRoute('species-dragons.sais'), this.toRoute('species-dragons.breath'), this.use('toLeft', {
      duration
    }), this.reverse('toRight', {
      duration
    }));
  }
});