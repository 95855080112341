define("dragon-dice/pods/components/unit-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XyCnKvJY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unit-swiper\",null,[[\"enabled\"],[[24,[\"slide\"]]]],{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"hash\",null,[[\"faces\",\"chances\",\"averages\",\"army\"],[[28,\"component\",[\"unit-details/unit-faces\"],[[\"unit\"],[[24,[\"unit\"]]]]],[28,\"component\",[\"unit-details/unit-chances\"],[[\"unit\"],[[24,[\"unit\"]]]]],[28,\"component\",[\"unit-details/unit-averages\"],[[\"unit\"],[[24,[\"unit\"]]]]],[28,\"component\",[\"unit-details/unit-army\"],[[\"unit\",\"onAddToArmy\"],[[24,[\"unit\"]],[24,[\"onAddToArmy\"]]]]]]]],[24,[\"unit\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"unit\",\"faces\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"unit-details/unit-faces\",null,[[\"unit\"],[[24,[\"unit\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"not-eq\",[[24,[\"unit\",\"species\"]],\"dragons\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"unit-details/unit-chances\",null,[[\"unit\"],[[24,[\"unit\"]]]]],false],[0,\"\\n      \"],[1,[28,\"unit-details/unit-averages\",null,[[\"unit\"],[[24,[\"unit\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"onAddToArmy\"]],[28,\"not\",[[24,[\"onAddToArmy\",\"notProvided\"]]],null]],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"unit-details/unit-army\",null,[[\"unit\",\"onAddToArmy\"],[[24,[\"unit\"]],[24,[\"onAddToArmy\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/unit-details/template.hbs"
    }
  });

  _exports.default = _default;
});