define("dragon-dice/pods/components/spell-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E/5j2y2Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"spell\",\"spellList\"]],\"Dragons\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"svg\",true],[10,\"class\",\"symbol\"],[8],[0,\"\\n    \"],[7,\"use\",true],[10,\"xlink:href\",\"/images/faces/dragons/face-dragon-breath-d.svg#a\",\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[1,[24,[\"spell\",\"cost\"]],false],[1,[28,\"if\",[[24,[\"spell\",\"multiplicative\"]],\"X\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/spell-icon/template.hbs"
    }
  });

  _exports.default = _default;
});