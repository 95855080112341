define("dragon-dice/pods/components/species-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hZiKq/t2",
    "block": "{\"symbols\":[\"species\"],\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"button-nav\",[[12,\"href\",[28,\"href-to\",[\"species\",[23,1,[\"id\"]]],null]],[12,\"class\",[29,[\"species--\",[23,1,[\"id\"]]]]],[12,\"data-test-species-button\",\"\"]],[[\"@label\"],[[23,1,[\"name\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/species-list/template.hbs"
    }
  });

  _exports.default = _default;
});