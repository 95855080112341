define("dragon-dice/pods/components/unit-swiper/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      if (!this.get('enabled')) {
        return;
      }

      (0, _jquery.default)(this.element.querySelector('div')).slick({
        arrows: false,
        dots: true
      });
    }

  });

  _exports.default = _default;
});