define("dragon-dice/pods/species/sais/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P37z3q9n",
    "block": "{\"symbols\":[\"sai\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"one-column\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"list-icons\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[5,\"face-card\",[[12,\"aria-expanded\",[23,1,[\"expanded\"]]]],[[\"@onClick\",\"@title\",\"@subtitle\"],[[28,\"action\",[[23,0,[]],[28,\"toggle\",[\"expanded\",[23,1,[]]],null]],null],[23,1,[\"title\"]],[28,\"join\",[\", \",[23,1,[\"phases\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[5,\"sai-face\",[],[[\"@species\",\"@action\"],[[22,\"species\"],[23,1,[\"id\"]]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"liquid-if\",[[23,1,[\"expanded\"]]],[[\"class\"],[\"article\"]],{\"statements\":[[0,\"          \"],[7,\"p\",true],[8],[0,\"\\n            \"],[1,[23,1,[\"description\"]],false],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species/sais/template.hbs"
    }
  });

  _exports.default = _default;
});