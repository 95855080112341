define("dragon-dice/pods/components/unit-details/unit-army/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('armies', this.store.findAll('army'));
    },

    // TODO: This was changed from `armies.@each.allUnits.@each.id`; need to verify it still works.
    unitArmies: Ember.computed('armies.@each.allUnits', 'unit.id', function () {
      return this.armies.filter(army => army.allUnits.findBy('id', this.unit.id));
    })
  });

  _exports.default = _default;
});