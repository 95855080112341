define("dragon-dice/data/equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "units": [{
      "id": "speed-slippers",
      "name": "Speed Slippers",
      "type": "maneuver",
      "rarity": "small",
      "health": 4
    }]
  };
  _exports.default = _default;
});