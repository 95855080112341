define("dragon-dice/pods/index/spells/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uUlSTMAK",
    "block": "{\"symbols\":[\"element\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"list-tiles\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[5,\"button-nav\",[[12,\"href\",[28,\"href-to\",[\"spell-element\",[23,1,[\"id\"]]],null]],[12,\"class\",[29,[\"element--\",[23,1,[\"id\"]]]]]],[[\"@label\"],[[29,[[23,1,[\"title\"]],\" Spells\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/index/spells/template.hbs"
    }
  });

  _exports.default = _default;
});