define("dragon-dice/pods/components/banner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    role: 'banner',
    attributeBindings: ['role']
  });

  _exports.default = _default;
});