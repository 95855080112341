define("dragon-dice/pods/species/spells/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ke+GApVi",
    "block": "{\"symbols\":[\"spell\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"one-column\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"list-icons max-common\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[5,\"face-card\",[[12,\"aria-expanded\",[23,1,[\"expanded\"]]]],[[\"@onClick\",\"@title\",\"@subtitle\"],[[28,\"action\",[[23,0,[]],[28,\"toggle\",[\"expanded\",[23,1,[]]],null]],null],[23,1,[\"title\"]],[28,\"join\",[\" · \",[28,\"compact\",[[28,\"array\",[[23,1,[\"spellList\"]],[28,\"if\",[[23,1,[\"castFromReserves\"]],\"Reserves\",null],null],[28,\"if\",[[23,1,[\"castableWithCantrip\"]],\"Cantrip\",null],null]],null]],null]],null]]],{\"statements\":[[0,\"\\n          \"],[5,\"spell-icon\",[],[[\"@spell\"],[[23,1,[]]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"liquid-if\",[[23,1,[\"expanded\"]]],[[\"class\"],[\"article\"]],{\"statements\":[[0,\"          \"],[7,\"p\",true],[8],[1,[23,1,[\"description\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"castFromReserves\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\",true],[8],[7,\"em\",true],[8],[0,\"This spell is castable from reserves.\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"castableWithCantrip\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\",true],[8],[7,\"em\",true],[8],[0,\"This spell is castable using Cantrip results.\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species/spells/template.hbs"
    }
  });

  _exports.default = _default;
});