define("dragon-dice/pods/components/modals/x-add-to-army/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sgIz2/Pu",
    "block": "{\"symbols\":[\"army\",\"@isShown\",\"@onClose\"],\"statements\":[[5,\"modal\",[[12,\"data-test-details-membership--add-list\",\"\"]],[[\"@isShown\",\"@onClose\"],[[23,2,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"form\",false],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"onSubmit\"]],[24,[\"newArmy\"]]],null]],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Add this unit to an army:\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"radio labeled\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredArmies\"]]],null,{\"statements\":[[0,\"        \"],[7,\"label\",true],[11,\"data-test-details-membership--add-list-item\",[23,1,[\"id\"]]],[8],[0,\"\\n          \"],[7,\"input\",true],[10,\"name\",\"army\"],[11,\"value\",[28,\"readonly\",[[23,1,[]]],null]],[11,\"checked\",[28,\"eq\",[[24,[\"newArmy\"]],[23,1,[]]],null]],[11,\"onchange\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"newArmy\"]]],null],[23,1,[]]],null]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-icon\"],[12,\"type\",\"reset\"],[3,\"action\",[[23,0,[]],[24,[\"onClose\"]]]],[8],[1,[28,\"i\",[\"back\"],null],false],[9],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"btn btn-icon\"],[11,\"disabled\",[28,\"not\",[[24,[\"newArmy\"]]],null]],[10,\"data-test-details-membership--add-add\",\"\"],[10,\"type\",\"submit\"],[8],[1,[28,\"i\",[\"yep\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/modals/x-add-to-army/template.hbs"
    }
  });

  _exports.default = _default;
});