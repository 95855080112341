define("dragon-dice/router", ["exports", "dragon-dice/config/environment", "dragon-dice/mixins/google-pageview"], function (_exports, _environment, _googlePageview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_googlePageview.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('index', {
      path: ''
    }, function () {
      this.route('species', {
        path: ''
      });
      this.route('spells');
      this.route('armies');
    });
    this.route('species', {
      path: 'species/*'
    });
    this.route('species', {
      path: 'species/:species_id'
    }, function () {
      this.route('units', {
        path: ''
      });
      this.route('sais');
      this.route('spells');
      this.route('breath');
      this.route('details');
    });
    this.route('spell-element', {
      path: 'spells/:element_id'
    });
    this.route('army', {
      path: 'armies/:army_id'
    }, function () {
      this.route('details');
      this.route('sais');
    });
  });
  var _default = Router;
  _exports.default = _default;
});