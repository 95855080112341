define("dragon-dice/pods/components/unit-details/unit-chances/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Pe5ORj5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"h5\",true],[8],[0,\"Favorable Result Chance\"],[9],[0,\"\\n  \"],[1,[28,\"graphs/graph-average\",null,[[\"data\",\"barValue\",\"format\",\"species\"],[[24,[\"unit\",\"faceBreakdown\"]],\"favResult\",\"0%\",[24,[\"unit\",\"species\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/unit-details/unit-chances/template.hbs"
    }
  });

  _exports.default = _default;
});