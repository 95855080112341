define("dragon-dice/pods/species/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P8QdtAtF",
    "block": "{\"symbols\":[],\"statements\":[[5,\"banner\",[[12,\"class\",[29,[\"species--\",[24,[\"model\",\"id\"]]]]]],[[\"@title\"],[[24,[\"model\",\"name\"]]]],{\"statements\":[[0,\"\\n  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"link-left\",\"index.species\"]],{\"statements\":[[1,[28,\"i\",[\"back\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"species.units\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"Units\"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"species.sais\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"SAIs\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"hasSpells\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"species.spells\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"Spells\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"id\"]],\"dragons\"],null]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"species.breath\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"Breath\"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"species.details\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"Details\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"section\",true],[11,\"class\",[29,[\"content species--\",[24,[\"model\",\"id\"]]]]],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species/template.hbs"
    }
  });

  _exports.default = _default;
});