define("dragon-dice/models/army", ["exports", "ember-data", "ember-pouch"], function (_exports, _emberData, _emberPouch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.elements = void 0;
  const {
    attr
  } = _emberData.default;
  const elements = ['fire', 'earth', 'water', 'air', 'life'];
  _exports.elements = elements;

  var _default = _emberPouch.Model.extend({
    dataService: Ember.inject.service('data'),
    name: attr('string'),
    units: attr({
      defaultValue: () => []
    }),
    color: attr('string', {
      defaultValue: () => elements[Math.floor(Math.random() * elements.length)]
    }),
    sais: Ember.computed('allUnits.@each.faces', function () {
      let saiFaces = this.allUnits.map(u => u.faces.filter(f => f.type === 'sai').map(f => {
        f.species = u.species;
        f.raceSai = `${u.species}:${f.action}`;
        return f;
      }));
      return [].concat.apply([], saiFaces).uniqBy('raceSai').sortBy('action');
    }),
    allUnits: Ember.computed('units.@each.{id,qty}', function () {
      let data = this.get('dataService');
      return this.get('units').map(unit => data.getUnit(unit.id, {
        qty: unit.qty
      }));
    }),
    faceBreakdown: Ember.computed('allUnits.@each.{faceBreakdown,qty}', function () {
      let breakdown = {
        melee: [],
        missile: [],
        magic: [],
        maneuver: [],
        save: []
      };
      this.get('allUnits').mapBy('faceBreakdown').forEach(b => {
        breakdown.melee.push(b.findBy('type', 'melee'));
        breakdown.missile.push(b.findBy('type', 'missile'));
        breakdown.magic.push(b.findBy('type', 'magic'));
        breakdown.maneuver.push(b.findBy('type', 'maneuver'));
        breakdown.save.push(b.findBy('type', 'save'));
      });
      return Object.keys(breakdown).map(type => ({
        type,
        average: Math.round(this._average(breakdown[type]) * 100) / 100,
        favResult: Math.round(this._favResult(breakdown[type]) * 100) / 100,
        stDev: Math.round(this._stDev(breakdown[type]) * 100) / 100
      }));
    }),

    addUnit(id) {
      let unitExists = this.get('dataService').getUnit(id);

      if (!unitExists) {
        throw new Error(`No unit found with ID: ${id}.`);
      }

      let existingUnit = this.get('units').findBy('id', id);

      if (existingUnit) {
        Ember.set(existingUnit, 'qty', Ember.get(existingUnit, 'qty') + 1);
      } else {
        this.get('units').pushObject({
          id,
          qty: 1
        });
      }

      return this;
    },

    changeUnitQty(id, change) {
      let existingUnit = this.units.findBy('id', id);

      if (!existingUnit && change === 1) {
        this.addUnit(id);
        return this;
      }

      let newQty = existingUnit.qty + change;

      if (newQty === 0) {
        this.set('units', this.get('units').reject(u => u.id === id));
      } else {
        Ember.set(existingUnit, 'qty', newQty);
      }

      return this;
    },

    _average(unitValues) {
      return unitValues.reduce((acc, cur) => acc + cur.average * cur.qty, 0);
    },

    _favResult(unitValues) {
      return 1 - unitValues.reduce((acc, cur) => acc * Math.pow(1 - cur.favResult, cur.qty), 1);
    },

    _stDev(unitValues) {
      let stDevs = unitValues.reduce((acc, cur) => acc + cur.qty * Math.pow(cur.stDev, 2), 0);
      return Math.sqrt(stDevs);
    }

  });

  _exports.default = _default;
});