define("dragon-dice/helpers/unslugify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unslugify = unslugify;
  _exports.default = void 0;

  function unslugify([val]) {
    return val.replace('-', ' ');
  }

  var _default = Ember.Helper.helper(unslugify);

  _exports.default = _default;
});