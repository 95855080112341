define("dragon-dice/pods/army/index/controller", ["exports", "dragon-dice/models/unit"], function (_exports, _unit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const groupByOptions = ['type', 'rarity', 'species'],
        searchByOptions = ['army', 'all'];

  var _default = Ember.Controller.extend({
    queryParams: ['groupBy', 'searchBy', 'search'],
    groupByOptions,
    searchByOptions,
    groupBy: 'type',
    searchBy: 'army',
    search: '',
    unitsToShow: Ember.computed('searchBy', 'search', 'armySearchResults', 'searchResults', function () {
      if (this.searchBy === 'all' && this.search) {
        return this.searchResults;
      } else {
        return this.armySearchResults;
      }
    }),
    armySearchResults: Ember.computed('model.army.allUnits.@each.name', 'search', function () {
      return this.search ? this.model.army.allUnits.filter(({
        name
      }) => name.toLowerCase().includes(this.search.toLowerCase())) : this.model.army.allUnits;
    }),
    searchResults: Ember.computed('model.army.allUnits.@each.{name,qty}', 'model.addUnitSearchResults.@each', function () {
      let unitsInArmy = this.model.army.allUnits.filter(u => u.qty).mapBy('id');
      return this.model.addUnitSearchResults.map(u => {
        let mappedUnit = _unit.default.create(u, {
          added: unitsInArmy.includes(u.id)
        }),
            unitInArmy = this.model.army.allUnits.findBy('id', u.id);

        if (unitInArmy) {
          mappedUnit.set('qty', unitInArmy.qty);
        }

        return mappedUnit;
      });
    }),
    actions: {
      clearSearch() {
        this.setProperties({
          searchBy: 'army',
          search: ''
        });
      }

    }
  });

  _exports.default = _default;
});