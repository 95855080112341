define("dragon-dice/helpers/action-nobubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.actionNoBubble = actionNoBubble;
  _exports.default = void 0;

  function actionNoBubble([action]) {
    return function (event) {
      event.stopPropagation();
      return action(event);
    };
  }

  var _default = Ember.Helper.helper(actionNoBubble);

  _exports.default = _default;
});