define("dragon-dice/pods/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ccghHv2",
    "block": "{\"symbols\":[],\"statements\":[[5,\"banner\",[],[[\"@title\"],[\"Dragon Dice\"]],{\"statements\":[[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"index.species\"]],{\"statements\":[[0,\"Species\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"index.spells\"]],{\"statements\":[[0,\"Spells\"]],\"parameters\":[]}],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"index.armies\"]],{\"statements\":[[0,\"Armies\"]],\"parameters\":[]}],[0,\"\\n\"],[0,\"  \"],[7,\"a\",false],[12,\"class\",\"link-right\"],[3,\"on\",[\"click\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"showInfo\"]]],null],true],null]]],[8],[1,[28,\"i\",[\"info\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"content one-column-wide\"],[8],[0,\"\\n  \"],[1,[28,\"liquid-outlet\",null,[[\"enableGrowth\"],[false]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"modal\",[[12,\"data-test-info-modal\",[23,0,[\"showInfo\"]]]],[[\"@isShown\"],[[23,0,[\"showInfo\"]]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[7,\"img\",true],[10,\"src\",\"/images/dd-logo.gif\"],[10,\"alt\",\"Dragon Dice™\"],[8],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Based on rules version 4.01.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Dragon Dice™ is a registered trademark of SFR, Inc. All rights reserved.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"This fansite is unaffiliated with SFR, Inc.\"],[9],[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"link\"],[10,\"href\",\"http://www.dragondice.com/\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"Visit DragonDice.com\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"a\",false],[12,\"class\",\"link\"],[12,\"data-test-info-modal-close\",\"\"],[3,\"on\",[\"click\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"showInfo\"]]],null],false],null]]],[8],[0,\"Close\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/index/template.hbs"
    }
  });

  _exports.default = _default;
});