define("dragon-dice/models/species", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    hasSpells: true,
    backgroundStyle: Ember.computed('id', function () {
      let id = this.get('id');
      return Ember.String.htmlSafe(`background-image: url('species/backgrounds/${id}.jpg')`);
    }),
    sais: Ember.computed('units', function () {
      return this.get('units').mapBy('faces').reduce((prev, curr) => [...prev, ...curr]).uniqBy('action').sortBy('action').mapBy('action').filter(x => x);
    })
  });

  _exports.default = _default;
});