define("dragon-dice/pods/components/unit-face/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['unit.species', 'unit.rarity', 'small'],
    faceUrl: Ember.computed('face.{action,type,value,variant}', 'unit.{id,species,rarity,type}', function () {
      let {
        id,
        species,
        rarity,
        type: unitType
      } = this.unit,
          face = this.face; // If a face wasn't provided, render the ID

      if (!face) {
        let idType = ['monster', 'dragon'].includes(unitType) ? id.replace('\'', '') : `${unitType}-${rarity}`;
        return `/images/faces/${species}/id-${idType}.svg`;
      }

      let {
        action,
        type,
        value,
        variant
      } = face;

      if (type === 'id' && (rarity === 'monster' || unitType === 'dragon')) {
        return `/images/faces/${species}/id-${id.replace('\'', '')}.svg`;
      } else if (type === 'id') {
        let idType = unitType === 'monster' ? id : `${unitType}-${rarity}`;
        return `/images/faces/${species}/id-${idType}.svg`;
      } else {
        let unitValue,
            faceType = type !== 'sai' ? type : action,
            variantUrlPart = variant ? `${variant}-` : '';

        if (rarity === 'monster') {
          unitValue = 'm';
        } else if (unitType === 'dragon') {
          unitValue = 'd';
        } else {
          unitValue = value;
        }

        return `/images/faces/${species}/face-${faceType}-${variantUrlPart}${unitValue}.svg`;
      }
    })
  });

  _exports.default = _default;
});