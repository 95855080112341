define("dragon-dice/pods/components/x-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yOWKzRBp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"ul\",true],[8],[14,1],[9],[0,\"\\n\"],[7,\"div\",false],[12,\"class\",\"overlay\"],[12,\"role\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[28,\"mut\",[[23,0,[\"isShown\"]]],null],false],null]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/x-menu/template.hbs"
    }
  });

  _exports.default = _default;
});