define("dragon-dice/pods/species/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SPe42ALy",
    "block": "{\"symbols\":[\"ra\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"one-column\"],[8],[0,\"\\n  \"],[7,\"article\",true],[8],[0,\"\\n    \"],[7,\"q\",true],[8],[1,[24,[\"model\",\"description\"]],false],[9],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"Species Abilities\"],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"list-abilities\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"abilities\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"h4\",true],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[1,[23,1,[\"description\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/species/details/template.hbs"
    }
  });

  _exports.default = _default;
});