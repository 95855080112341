define("dragon-dice/pods/components/unit-details/unit-army/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cc/2zso6",
    "block": "{\"symbols\":[\"army\"],\"statements\":[[7,\"h5\",true],[10,\"data-test-details-membership\",\"\"],[8],[0,\"Army Membership\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"unitArmies\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"list-armies\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"unitArmies\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"data-test-details-membership--list-item\",\"\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"empty\"],[8],[0,\"Not a member of any armies.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[7,\"p\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-medium\"],[12,\"data-test-details-membership--add\",\"\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"onAddToArmy\"]],[24,[\"unit\"]]]],[8],[0,\"Add to Army...\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/unit-details/unit-army/template.hbs"
    }
  });

  _exports.default = _default;
});