define("dragon-dice/pods/index/armies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8jsVB2wY",
    "block": "{\"symbols\":[\"army\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"list-tiles unconstrained scrollable\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-medium\"],[12,\"data-test-army-create\",\"\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"createArmy\"]]]],[8],[0,\"Create Army\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[5,\"button-nav\",[[12,\"href\",[28,\"href-to\",[\"army.index\",[23,1,[\"id\"]]],null]],[12,\"class\",[29,[\"element--\",[23,1,[\"color\"]]]]],[12,\"data-test-army-button\",\"\"]],[[\"@label\"],[[23,1,[\"name\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/index/armies/template.hbs"
    }
  });

  _exports.default = _default;
});