define("dragon-dice/pods/components/unit-overview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8u7lFIC",
    "block": "{\"symbols\":[\"@unit\",\"@onAddToArmy\"],\"statements\":[[5,\"face-card\",[[12,\"class\",[29,[\"species--\",[24,[\"unit\",\"species\"]]]]],[12,\"aria-expanded\",[24,[\"unit\",\"expanded\"]]]],[[\"@onClick\",\"@onIncQty\",\"@onDecQty\",\"@title\",\"@subtitle\",\"@isEditing\"],[[28,\"action\",[[23,0,[]],[24,[\"onUnitSelected\"]],[24,[\"unit\"]]],null],[28,\"action-nobubble\",[[28,\"action\",[[23,0,[]],[24,[\"onUnitQtyChanged\"]],[24,[\"unit\",\"id\"]],1],null]],null],[28,\"action-nobubble\",[[28,\"action\",[[23,0,[]],[24,[\"onUnitQtyChanged\"]],[24,[\"unit\",\"id\"]],-1],null]],null],[24,[\"unit\",\"name\"]],[24,[\"unit\",\"subtitle\"]],[22,\"isEditing\"]]],{\"statements\":[[0,\"\\n  \"],[5,\"unit-face\",[[12,\"class\",\"face\"]],[[\"@unit\"],[[22,\"unit\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"liquid-if\",[[28,\"and\",[[24,[\"unit\",\"expanded\"]],[28,\"not\",[[24,[\"shouldOverflow\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"unit-details\",[[12,\"data-test-details\",[24,[\"unit\",\"id\"]]]],[[\"@unit\",\"@slide\",\"@onAddToArmy\"],[[23,1,[]],true,[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"unit\",\"expanded\"]],[24,[\"shouldOverflow\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"to-elsewhere\",null,[[\"named\",\"send\"],[[24,[\"overflowContainer\"]],[28,\"component\",[\"unit-details\"],[[\"unit\",\"slide\",\"data-test-details\",\"onAddToArmy\",\"class\"],[[24,[\"unit\"]],false,[24,[\"unit\",\"id\"]],[24,[\"onAddToArmy\"]],[28,\"concat\",[\"species--\",[24,[\"unit\",\"species\"]]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/unit-overview/template.hbs"
    }
  });

  _exports.default = _default;
});