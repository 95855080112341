define("dragon-dice/data/species/dragonkin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "id": "dragonkin",
    "name": "Dragonkin",
    "elements": [],
    "description": "Dragonkin are the distant relatives of full-sized dragons. Like dragons, they can be summoned into play during a game, but unlike their less-predicable forerunners, Dragonkin will join your armies and fight for you!",
    "hasSpells": false,
    "abilities": [{
      "title": "Actions",
      "description": "Dragonkin units cannot roll for magic or missile actions. Dragonkin units also cannot roll during a dragon attack that includes a dragon matching their color, an Ivory Dragon, Ivory Hybrid Dragon, or White Dragon, but may take damage during the attack. Dragonkin may take part in dragon attacks with other dragons as normal."
    }, {
      "title": "Automatic Saves",
      "description": "All Dragonkin have armored skin, which grants them automatic save results equal to their health. These save results are added to their individual and army’s save rolls."
    }],
    "units": [{
      "id": "dragonkin-champion",
      "name": "Dragonkin Champion",
      "type": "champion",
      "rarity": "champion",
      "health": 4,
      "faces": [{
        "value": 4,
        "type": "id"
      }, {
        "value": 4,
        "type": "sai",
        "action": "belly"
      }, {
        "value": 4,
        "type": "sai",
        "action": "fly"
      }, {
        "value": 4,
        "type": "sai",
        "action": "breath"
      }, {
        "value": 4,
        "type": "sai",
        "action": "smite"
      }, {
        "value": 4,
        "type": "sai",
        "action": "dragonkin-champion-logo"
      }]
    }, {
      "id": "dragonchamp",
      "name": "Dragonchamp",
      "type": "heavy",
      "rarity": "rare",
      "health": 3,
      "faces": [{
        "value": 3,
        "type": "id"
      }, {
        "value": 2,
        "type": "maneuver"
      }, {
        "value": 4,
        "type": "melee"
      }, {
        "value": 3,
        "type": "sai",
        "action": "breath"
      }, {
        "value": 3,
        "type": "melee"
      }, {
        "value": 3,
        "type": "sai",
        "action": "belly"
      }]
    }, {
      "id": "dragonspy",
      "name": "Dragonspy",
      "type": "light",
      "rarity": "rare",
      "health": 3,
      "faces": [{
        "value": 3,
        "type": "id"
      }, {
        "value": 3,
        "type": "maneuver"
      }, {
        "value": 3,
        "type": "melee"
      }, {
        "value": 3,
        "type": "sai",
        "action": "breath"
      }, {
        "value": 3,
        "type": "melee"
      }, {
        "value": 3,
        "type": "sai",
        "action": "belly"
      }]
    }, {
      "id": "dragonsteed",
      "name": "Dragonsteed",
      "type": "cavalry",
      "rarity": "rare",
      "health": 3,
      "faces": [{
        "value": 3,
        "type": "id"
      }, {
        "value": 3,
        "type": "melee"
      }, {
        "value": 3,
        "type": "maneuver"
      }, {
        "value": 3,
        "type": "sai",
        "action": "breath"
      }, {
        "value": 3,
        "type": "maneuver"
      }, {
        "value": 3,
        "type": "belly"
      }]
    }, {
      "id": "dragonhero",
      "name": "Dragonhero",
      "type": "heavy",
      "rarity": "uncommon",
      "health": 2,
      "faces": [{
        "value": 2,
        "type": "id"
      }, {
        "value": 3,
        "type": "melee"
      }, {
        "value": 2,
        "type": "melee"
      }, {
        "value": 1,
        "type": "maneuver"
      }, {
        "value": 2,
        "type": "melee"
      }, {
        "value": 2,
        "type": "sai",
        "action": "belly"
      }]
    }, {
      "id": "dragonsentry",
      "name": "Dragonsentry",
      "type": "light",
      "rarity": "uncommon",
      "health": 2,
      "faces": [{
        "value": 2,
        "type": "id"
      }, {
        "value": 2,
        "type": "melee"
      }, {
        "value": 2,
        "type": "melee"
      }, {
        "value": 2,
        "type": "maneuver"
      }, {
        "value": 2,
        "type": "melee"
      }, {
        "value": 2,
        "type": "sai",
        "action": "belly"
      }]
    }, {
      "id": "dragonmount",
      "name": "Dragonmount",
      "type": "cavalry",
      "rarity": "uncommon",
      "health": 2,
      "faces": [{
        "value": 2,
        "type": "id"
      }, {
        "value": 2,
        "type": "melee"
      }, {
        "value": 2,
        "type": "maneuver"
      }, {
        "value": 2,
        "type": "melee"
      }, {
        "value": 2,
        "type": "maneuver"
      }, {
        "value": 2,
        "type": "sai",
        "action": "belly"
      }]
    }, {
      "id": "dragontroop",
      "name": "Dragontroop",
      "type": "heavy",
      "rarity": "common",
      "health": 1,
      "faces": [{
        "value": 1,
        "type": "id"
      }, {
        "value": 1,
        "type": "melee"
      }, {
        "value": 1,
        "type": "melee"
      }, {
        "value": 1,
        "type": "maneuver"
      }, {
        "value": 1,
        "type": "melee"
      }, {
        "value": 1,
        "type": "sai",
        "action": "belly"
      }]
    }, {
      "id": "dragonscout",
      "name": "Dragonscout",
      "type": "light",
      "rarity": "common",
      "health": 1,
      "faces": [{
        "value": 1,
        "type": "id"
      }, {
        "value": 1,
        "type": "maneuver"
      }, {
        "value": 1,
        "type": "melee"
      }, {
        "value": 1,
        "type": "maneuver"
      }, {
        "value": 1,
        "type": "melee"
      }, {
        "value": 1,
        "type": "sai",
        "action": "belly"
      }]
    }, {
      "id": "dragonfoal",
      "name": "Dragonfoal",
      "type": "cavalry",
      "rarity": "common",
      "health": 1,
      "faces": [{
        "value": 1,
        "type": "id"
      }, {
        "value": 1,
        "type": "maneuver"
      }, {
        "value": 1,
        "type": "maneuver"
      }, {
        "value": 1,
        "type": "melee"
      }, {
        "value": 1,
        "type": "maneuver"
      }, {
        "value": 1,
        "type": "sai",
        "action": "belly"
      }]
    }]
  };
  _exports.default = _default;
});