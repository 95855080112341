define("dragon-dice/pods/components/unit-face/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EOVaDn7+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[8],[0,\"\\n  \"],[7,\"use\",true],[11,\"class\",[24,[\"face\",\"type\"]]],[11,\"xlink:href\",[29,[[22,\"faceUrl\"],\"#a\"]],\"http://www.w3.org/1999/xlink\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"unit\",\"qty\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"face-quantity background-face\"],[10,\"data-test-face-qty\",\"\"],[8],[1,[24,[\"unit\",\"qty\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dragon-dice/pods/components/unit-face/template.hbs"
    }
  });

  _exports.default = _default;
});