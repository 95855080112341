define("dragon-dice/helpers/label-face", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    sais: Ember.inject.service(),

    compute([face]) {
      let {
        action,
        label,
        type
      } = face,
          typeLabel;

      if (type === 'id') {
        return 'ID';
      } else if (label) {
        typeLabel = label;
      } else if (action) {
        let {
          title
        } = this.sais.mapSai(action);
        typeLabel = title;
      } else {
        typeLabel = type;
      }

      return `${typeLabel}${face.value ? ' (' + face.value + ')' : ''}`;
    }

  });

  _exports.default = _default;
});