define("dragon-dice/pods/components/graphs/graph-average/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['species'],
    barValue: 'average',
    graphData: Ember.computed('data', 'barValue', 'stdValue', function () {
      let data = this.data || [],
          barValue = this.barValue,
          stdValue = this.stdValue,
          values = data.map(x => x[barValue] + (stdValue ? x[stdValue] : 0)),
          maxValue = Math.max.apply(Math, values);
      return data.map(datum => {
        let percentage = datum[barValue] / maxValue,
            stdPercentage = stdValue ? datum[stdValue] * 2 / maxValue : 0;
        return Object.assign({
          percentage,
          stdPercentage,
          style: Ember.String.htmlSafe(`width: calc(${percentage * 100}% + 7px)`),
          stdStyle: Ember.String.htmlSafe([`left: calc(${percentage * 100}%)`, `width: calc(${stdPercentage * 100}%)`].join(';'))
        }, datum);
      });
    })
  });

  _exports.default = _default;
});