define("dragon-dice/pods/species/units/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        species: this.modelFor('species'),
        armies: this.store.findAll('army')
      });
    }

  }

  _exports.default = _default;
});